<script setup lang="ts">
import { RouterView } from 'vue-router'
import HeaderSection from '@/components/commons/HeaderSection.vue'
import { useAuthStore } from '@/stores/auth'
import { computed, onMounted, ref, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useMainStore } from '@/stores/main'
import { usePrimeVue } from 'primevue/config'
import { useCompanyStore } from '@/stores/company'
import OnBoardingTour from './components/commons/OnBoardingTour.vue'
import { ToastMessageOptions } from 'primevue/toast'

const toast = useToast()
const authStore = useAuthStore()
const mainStore = useMainStore()
const companyStore = useCompanyStore()
const primevue = usePrimeVue()

const isMounted = ref(false)
const loading = computed(() => mainStore.loading)
const isDarkMode = computed(() => mainStore.isDarkMode)
const showWarningSessionExpired = computed(() => authStore.showWarningSessionExpired)

onMounted(() => {
  authStore.showWarningSessionExpired = false
  mainStore.resetServerMessages()

  authStore.getCookieInfos().then(() => {
    const companyId = new URL(location.toString()).searchParams.get('eid')
    if (companyId && mainStore.getCompanyById(Number(companyId))) {
      companyStore.company.id = Number(companyId)
    }

    if (isDarkMode.value) {
      primevue.changeTheme('lara-light-cyan', 'lara-dark-cyan', 'theme-link')
    } else {
      primevue.changeTheme('lara-dark-cyan', 'lara-light-cyan', 'theme-link')
    }

    mainStore.displayOnBoardingTour().finally(() => {
      mainStore.loading = false
      isMounted.value = true
    })
  })
})

watch(
  mainStore.serverResponseStatut,
  () => {
    if (mainStore.serverResponseStatut.errorMessages) {
      for (const errorMessage of mainStore.serverResponseStatut.errorMessages) {
        if (!errorMessage.alreadyDisplayed) {
          toast.add({
            severity: errorMessage.blocking ? 'error' : 'warn',
            summary: errorMessage.blocking ? 'Erreur' : 'Avertissement',
            detail: errorMessage.value,
            life: 7000,
          })

          if (!errorMessage.keepAfterDisplay) {
            errorMessage.alreadyDisplayed = true
          }
        }
      }
    }

    if (mainStore.serverResponseStatut.successMessage) {
      toast.add({
        severity: 'success',
        summary: 'Confirmation',
        detail: mainStore.serverResponseStatut.successMessage,
        life: 5000,
      })
    }
  },
  { deep: true },
)

watch(isDarkMode, () => {
  if (isDarkMode.value) {
    primevue.changeTheme('lara-light-cyan', 'lara-dark-cyan', 'theme-link')
  } else {
    primevue.changeTheme('lara-dark-cyan', 'lara-light-cyan', 'theme-link')
  }
})

const deleteErrorIfNecessary = (message: ToastMessageOptions) => {
  const index = mainStore.serverResponseStatut.errorMessages.findIndex(
    (errorMessage) => !errorMessage.keepAfterDisplay && errorMessage.value === message.detail,
  )
  if (index !== -1) {
    mainStore.serverResponseStatut.errorMessages.splice(index, 1)
  }
}

const logout = () => {
  authStore.logout()
}
</script>

<template>
  <HeaderSection v-if="!loading && isMounted" />
  <div class="p-3 md:p-5 mt-8 padding-xxl">
    <div v-if="loading || !isMounted" class="flex justify-content-center">
      <ProgressSpinner aria-label="Chargement de la page" />
    </div>
    <RouterView v-else />
  </div>
  <Toast @close="deleteErrorIfNecessary($event)" @lifeEnd="deleteErrorIfNecessary($event)" />
  <ConfirmPopup>
    <template #message="slotProps">
      <div class="p-confirm-popup-content">
        <span class="p-confirm-popup-icon" :class="slotProps.message.icon" />
        <span v-sane-html="slotProps.message.message" class="p-confirm-popup-message"></span>
      </div>
    </template>
  </ConfirmPopup>
  <OnBoardingTour v-if="!loading && isMounted" />
  <Dialog
    :visible="showWarningSessionExpired"
    modal
    blockScroll
    :draggable="false"
    :closable="false"
    header="Session expirée"
    class="darker-mask"
  >
    <p>Pour continuer, veuillez vous reconnecter</p>
    <template #footer>
      <Button @click="logout" label="Se connecter" />
    </template>
  </Dialog>
</template>
