<script setup lang="ts">
import { useVModel } from '@vueuse/core'
import { useCompanyStore } from '@/stores/company'
import { useMainStore } from '@/stores/main'
import { router } from '@/routes'
import { Router } from '@/models/router.model'
import { Companies } from '@/models/main.model'

type Props = {
  visible: boolean
  newSelectedCompany: Companies | null
  oldSelectedCompany: Companies | null
}

const props = defineProps<Props>()
const emit = defineEmits(['update:visible', 'update:newSelectedCompany', 'update:oldSelectedCompany'])

const mainStore = useMainStore()
const companyStore = useCompanyStore()
const visible = useVModel(props, 'visible', emit)
const newSelectedCompany = useVModel(props, 'newSelectedCompany', emit)
const oldSelectedCompany = useVModel(props, 'oldSelectedCompany', emit)

const cancelCompanyChange = () => {
  newSelectedCompany.value = props.oldSelectedCompany!
  visible.value = false
}

const confirmCompanyChange = () => {
  companyStore.company.id = newSelectedCompany.value!.id
  mainStore.selectedCompany = props.newSelectedCompany
  oldSelectedCompany.value = null
  visible.value = false
  router.push(Router.DASHBOARD)
}
</script>

<template>
  <Dialog v-model:visible="visible" modal :closable="false" header="Confirmation de changement d'entreprise">
    <p>
      Si vous changez d'entreprise sur cette page, vous allez être redirigé sur le tableau de bord et potentiellement
      perdre les données du formulaire de votre page.
    </p>
    <p class="text-center">Voulez-vous changer d'entreprise ?</p>
    <div class="flex justify-content-center align-items-center gap-3 mt-4 flex-column md:flex-row">
      <Tag rounded severity="warning" :value="oldSelectedCompany?.name + ' (' + oldSelectedCompany?.siret + ')'" />
      <i class="pi pi-arrow-down md:-rotate-90" style="font-size: 1.5rem" />
      <Tag rounded severity="success" :value="newSelectedCompany?.name + ' (' + newSelectedCompany?.siret + ')'" />
    </div>
    <template #footer>
      <Button @click="cancelCompanyChange" label="Annuler" severity="secondary" class="p-button-sm-mobile" />
      <Button
        @click="confirmCompanyChange"
        label="Changer d'entreprise"
        severity="primary"
        class="p-button-sm-mobile"
      />
    </template>
  </Dialog>
</template>
