<script setup lang="ts">
import { STATUS_COMPANY } from '@/models/main.model'

type Props = {
  status: STATUS_COMPANY
}

const props = defineProps<Props>()
</script>
<template>
  <Tag v-if="props.status === STATUS_COMPANY.ACTIVE" :value="props.status" severity="success" />
  <Tag v-else-if="props.status === STATUS_COMPANY.NOT_SUBSCRIBED" :value="props.status" severity="warning" />
  <Tag v-else-if="props.status === STATUS_COMPANY.CREATION_IN_PROGRESS" :value="props.status" severity="info" />
</template>
