import { capitalize, dateFRtoBasic, dateToFRFormat } from '@/utils/index'
import {
  AbsencePayload,
  RegularizationAgircArrcoContractPayload,
  COMPANY_DECLARATION_PANEL,
  COMPANY_DECLARATION_PANEL_LABEL,
  CodeAmount,
  CodeAmountPayload,
  CompanyDeclaration,
  CompanyDeclarationAssujettissement,
  CompanyDeclarationBordereau,
  CompanyDeclarationContribution,
  CompanyDeclarationCotisation,
  CompanyDeclarationCotisationSpecifique,
  CompanyDeclarationDocket,
  CompanyDeclarationPayload,
  RegularizationCongesSpectaclePayload,
  ContratSalarieEligible,
  ContributionPayload,
  DECLARATION_TYPE,
  DECLARATION_TYPE_LABEL,
  DefaultValuePayload,
  EditDatableValue,
  EligibleEmployeeContract,
  EligibleSocialOrganization,
  ExemptionPayload,
  ExtraHoursPayload,
  INDIVIDUAL_DECLARATION_PANEL,
  INDIVIDUAL_DECLARATION_PANEL_LABEL,
  INDIVIDUAL_DECLARATION_SALARY,
  IndividualDeclaration,
  IndividualDeclarationPayload,
  MappingError,
  MappingErrorPayload,
  NetTaxIncomeApprenticeDetails,
  NetTaxIncomeApprenticeDetailsPayload,
  OrganismeEligible,
  REPORT_STATUS,
  Regularization,
  RegularizationEmployeeBase,
  RegularizationEmployeeBasePayload,
  RegularizationContractComplementaryOrganism,
  RegularizationEmployeeContribution,
  RegularizationEmployeeContributionPayload,
  RegularizationEmployeeFranceTravail,
  RegularizationPayload,
  STATUS,
  STATUS_LABEL,
  RegularizationDGFIPPayload,
  RegularizationPanel,
  RegularizationWorkingTimePayload,
  RegularizationAbsencePayload,
  RegularizationBonusPayload,
  RegularizationComplementaryOrganismPayload,
  RegularizationEmployeeDgfipPayload,
  RegularizationEmployeeDgfip,
  RegularizationCIBTPPayload,
  CIBTP_CONTRIBUTION_CODE,
  CIBTP_CONTRIBUTION_LABEL,
  RegularizationFranceTravailPayload,
  RegularizationPaymentPayload,
} from './declaration.model'
import { SOCIAL_ORGANIZATIONS } from './company.model'
import { ObjKeyValueString } from './main.model'
import { convertRecordToMap } from '@/utils'

export class Helper {
  static URSSAF_DOETH_CTP = ['730']

  static URSSAF_GENERAL_REDUCTION_CTP = ['668', '671', '669', '801']

  static URSSAF_LEARNING_TAX_CTP = ['995', '996', '997']

  static URSSAF_CLASSIC_CTP = ['430', '432', '538']

  static URSSAF_PART = '018'

  static PENSION_PART = '106'

  static determineStatusLabel(status: STATUS): STATUS_LABEL {
    switch (status) {
      case STATUS.IN_ORDER:
        return STATUS_LABEL.IN_ORDER
      case STATUS.TO_COMPLETE:
        return STATUS_LABEL.TO_COMPLETE
      case STATUS.COMPLETE:
        return STATUS_LABEL.COMPLETE
      case STATUS.BEING_CORRECTED:
        return STATUS_LABEL.BEING_CORRECTED
      case STATUS.TO_CORRECT:
        return STATUS_LABEL.TO_CORRECT
      case STATUS.PENDING_PAYMENT:
        return STATUS_LABEL.PENDING_PAYMENT
      case STATUS.BLOCKED:
        return STATUS_LABEL.BLOCKED
      case STATUS.ON_GOING:
        return STATUS_LABEL.ON_GOING
      case STATUS.ACCEPTED:
        return STATUS_LABEL.ACCEPTED
      case STATUS.DEADLINE_EXCEEDED:
        return STATUS_LABEL.DEADLINE_EXCEEDED
    }
  }

  static determineStatusSeverity(status: STATUS): string {
    switch (status) {
      case STATUS.IN_ORDER:
      case STATUS.ACCEPTED:
        return 'success'
      case STATUS.TO_COMPLETE:
      case STATUS.TO_CORRECT:
      case STATUS.DEADLINE_EXCEEDED:
        return 'warning'
      case STATUS.PENDING_PAYMENT:
      case STATUS.BLOCKED:
        return 'danger'
      case STATUS.BEING_CORRECTED:
      case STATUS.ON_GOING:
      case STATUS.COMPLETE:
        return 'info'
      default:
        return 'info'
    }
  }

  static determineNatureLabel(nature: DECLARATION_TYPE): DECLARATION_TYPE_LABEL {
    switch (nature) {
      case DECLARATION_TYPE.DSN_ARRET_TRAVAIL:
        return DECLARATION_TYPE_LABEL.DSN_ARRET_TRAVAIL
      case DECLARATION_TYPE.DSN_FIN_CONTRAT_TRAVAIL:
        return DECLARATION_TYPE_LABEL.DSN_FIN_CONTRAT_TRAVAIL
      case DECLARATION_TYPE.DSN_FIN_CONTRAT_TRAVAIL_UNIQUE:
        return DECLARATION_TYPE_LABEL.DSN_FIN_CONTRAT_TRAVAIL_UNIQUE
      case DECLARATION_TYPE.DSN_MENSUELLE:
        return DECLARATION_TYPE_LABEL.DSN_MENSUELLE
      case DECLARATION_TYPE.DSN_REPRISE_SUITE_ARRET_TRAVAIL:
        return DECLARATION_TYPE_LABEL.DSN_REPRISE_SUITE_ARRET_TRAVAIL
      case DECLARATION_TYPE.INCONNU:
        return DECLARATION_TYPE_LABEL.INCONNU
    }
  }

  static determineReportStatusSeverity(status: REPORT_STATUS): string {
    switch (status) {
      case REPORT_STATUS.VALIDATED:
      case REPORT_STATUS.ACCEPTED_WITH_REMARKS:
        return 'success'
      case REPORT_STATUS.WAITING_RESPONSE:
        return 'warning'
      case REPORT_STATUS.REJECTED:
        return 'danger'
      case REPORT_STATUS.RECEIVED:
      case REPORT_STATUS.ON_GOING:
        return 'info'
      default:
        return 'info'
    }
  }

  static transformIndividualDeclarationPayload(
    payload: IndividualDeclarationPayload,
    id: number,
  ): IndividualDeclaration {
    const individualDeclaration: IndividualDeclaration = {
      id,
      period: { monthYear: payload.versement.periode, startDate: payload.dateDebut, endDate: payload.dateFin },
      contractId: payload.contratId ?? 0,
      status: payload.status ?? STATUS.ON_GOING,
      employee: { id: payload.salarieId ?? 0, name: payload.nom ?? '', firstName: payload.prenoms ?? '' },
      workingTime: {
        paidWorkingTime: payload.tempsTravailBases?.length > 0 ? payload.tempsTravailBases[0].mesure : 0,
        workingUnit: payload.tempsTravailBases?.length > 0 ? payload.tempsTravailBases[0].uniteMesure : '',
      },
      extraHours: Helper.transformIndividualDeclarationPayloadExtraHours(
        payload.heuresSupplementairesOuExceptionnelles,
      ),
      absences: Helper.transformIndividualDeclarationPayloadAbsence(payload.absences),
      salary: {
        uncappedMonthlyGross:
          payload.salaires.find((salaire) => salaire.code === INDIVIDUAL_DECLARATION_SALARY.UNCAPPED_MONTHLY_GROSS)
            ?.montant ?? 0,
        grossSubjectToUnemploymentInsuranceContributions:
          payload.salaires.find(
            (salaire) =>
              salaire.code === INDIVIDUAL_DECLARATION_SALARY.GROSS_SUBJECT_TO_UNEMPLOYMENT_INSURANCE_CONTRIBUTIONS,
          )?.montant ?? 0,
        reconstitutedGrossSalary:
          payload.salaires.find((salaire) => salaire.code === INDIVIDUAL_DECLARATION_SALARY.RECONSTITUTED_GROSS_SALARY)
            ?.montant ?? 0,
        basicGrossSalary:
          payload.salaires.find((salaire) => salaire.code === INDIVIDUAL_DECLARATION_SALARY.BASIC_GROSS_SALARY)
            ?.montant ?? 0,
      },
      base: {
        fields: Helper.transformIndividualDeclarationPayloadBases(payload.bases),
        pssModified: payload.complement?.changementPss ? Number(payload.complement.pss) : null,
      },
      baseComponents: Helper.transformIndividualDeclarationPayloadCodeAmount(payload.composantBases),
      otherIncomes: Helper.transformIndividualDeclarationPayloadCodeAmount(payload.autreRevenusBruts),
      contributions: Helper.transformIndividualDeclarationPayloadContributions(payload.cotisations),
      exemptions: Helper.transformIndividualDeclarationPayloadExemptions(payload.exonerations),
      bonus: Helper.transformIndividualDeclarationPayloadCodeAmount(payload.primes),
      compensations: Helper.transformIndividualDeclarationPayloadCodeAmount(payload.indemnites),
      totals: {
        netSocialAmount: payload.versement.montantNetSocial ?? 0,
        netTaxIncome: payload.versement.netFiscal ?? 0,
        netPayableBeforeTax: payload.versement.netFiscalPot ?? 0,
        grossIJSSAmount: payload.versement.montantIJSSBrut,
        deduction: payload.versement.abattementPas,
        netTaxIncomeApprenticeDetails: Helper.transformIndividualDeclarationPayloadApprenticeDetails(
          payload.versement.detailRnfApprentiStagiaire,
        ),
      },
      withHoldingTax: {
        base: payload.versement.assiettePas,
        amount: payload.versement.montantPas,
        tauxPAS: payload.versement.tauxPas,
        tauxPASCurrentMonth: payload.versement.tauxPasMoisCourant,
        tauxPASPreviousMonth: payload.versement.tauxPasMoisPrecedent,
        isTauxPASDiffFromPreviousMonth: payload.versement.diffTauxPasMoisPrecedent,
      },
      payment: {
        paymentDate: new Date(dateFRtoBasic(payload.versement.dateVersement)),
        netPayable: payload.versement.montantNet,
        netPayableAfterTax: payload.versement.montantNetApresIR,
      },
      admin: {
        shouldRecalculateGeneralReduction: !payload.desactivationCalculAutoReductionGenerale,
      },
      readonly: payload.modeLecture ?? false,
    }

    return individualDeclaration
  }

  static transformIndividualDeclarationPayloadAbsence(payload: AbsencePayload[]): EditDatableValue[] {
    const absences: EditDatableValue[] = []

    payload.forEach((absence) => {
      absences.push({
        key: absence.sType,
        startDate: {
          value: typeof absence.sDebut === 'string' ? new Date(dateFRtoBasic(absence.sDebut)) : absence.sDebut,
          required: true,
        },
        endDate: {
          value: typeof absence.sFin === 'string' ? new Date(dateFRtoBasic(absence.sFin)) : absence.sFin,
          required: true,
        },
        hours: { value: absence.nombreHeures, required: true, canBeZero: false },
        amount: { value: absence.montant, required: absence.montant > 0, canBeZero: true },
        code: absence.sType,
      })
    })

    return absences
  }

  static transformIndividualDeclarationPayloadCodeAmount(payload: CodeAmountPayload[]): EditDatableValue[] {
    const elements: EditDatableValue[] = []

    payload.forEach((element) => {
      elements.push({
        key: element.code,
        amount: { value: element.montant, required: true, canBeZero: false },
        code: element.code,
      })
    })

    return elements
  }

  static transformIndividualDeclarationPayloadExemptions(payload: ExemptionPayload[]): EditDatableValue[] {
    const exemptions: EditDatableValue[] = []

    payload.forEach((exemption) => {
      exemptions.push({
        key: exemption.code,
        base: { value: exemption.montantAssiette, required: true, canBeZero: true },
        amount: { value: exemption.montantExoneration, required: true, canBeZero: true },
        code: exemption.code,
      })
    })

    return exemptions
  }

  static transformIndividualDeclarationPayloadContributions(payload: ContributionPayload[]): EditDatableValue[] {
    const contributions: EditDatableValue[] = []

    payload.forEach((contribution) => {
      contributions.push({
        key: contribution.code,
        base: { value: contribution.montantAssiette, required: true, canBeZero: true },
        amount: { value: contribution.montantCotisation, required: true, canBeZero: true },
        rate: { value: contribution.taux, required: true, canBeZero: true },
        code: contribution.code,
        id: contribution.identifiant,
      })
    })

    return contributions
  }

  static transformIndividualDeclarationPayloadExtraHours(payload: ExtraHoursPayload[]): EditDatableValue[] {
    const extraHours: EditDatableValue[] = []

    payload.forEach((extraHour) => {
      extraHours.push({
        key: extraHour.code,
        hours: { value: extraHour.heures, required: true, canBeZero: false },
        amount: { value: extraHour.montant, required: true, canBeZero: false },
        code: extraHour.code,
      })
    })

    return extraHours
  }

  static transformIndividualDeclarationPayloadApprenticeDetails(
    payload: NetTaxIncomeApprenticeDetailsPayload,
  ): NetTaxIncomeApprenticeDetails | null {
    if (payload !== null) {
      return {
        annualMinimumWages: payload.smicAnnuel,
        previousAccumulation: payload.cumulPrecedent,
        netTaxableBulletin: payload.netImposableBulletin,
        currentAccumulation: payload.cumulCourant,
        netTaxIncome: payload.rnf,
        temporaryNetTaxIncome: payload.rnfProvisoire,
      }
    }
    return null
  }

  static transformIndividualDeclarationPayloadBases(payload: CodeAmountPayload[]): CodeAmount[] {
    const bases = [] as CodeAmount[]

    payload.forEach((base) => {
      bases.push({ code: base.code, amount: base.montant })
    })

    return bases
  }

  static transformIndividualDeclarationToDefaultValuePayload(
    individualDeclaration: IndividualDeclaration,
  ): DefaultValuePayload {
    const payload = {
      dateDebut: individualDeclaration.period.startDate,
      dateFin: individualDeclaration.period.endDate,
      tempsTravailBases: [
        {
          code: '01',
          mesure: individualDeclaration.workingTime.paidWorkingTime,
          uniteMesure: individualDeclaration.workingTime.workingUnit,
        },
      ],
      absences: Helper.transformIndividualDeclarationAbsencesToPayload(individualDeclaration.absences),
      heuresSupplementairesOuExceptionnelles: Helper.transformIndividualDeclarationExtraHoursToPayload(
        individualDeclaration.extraHours,
      ),
      salaires: Helper.transfromIndividualDeclaratonSalaryToPayload(individualDeclaration.salary),
      primes: Helper.transfromIndividualDeclaratonCodeAmountToPayload(individualDeclaration.bonus),
      indemnites: Helper.transfromIndividualDeclaratonCodeAmountToPayload(individualDeclaration.compensations),
      autreRevenusBruts: Helper.transfromIndividualDeclaratonCodeAmountToPayload(individualDeclaration.otherIncomes),
      bases: Helper.transfromIndividualDeclaratonBasesToPayload(individualDeclaration.base.fields),
      composantBases: Helper.transfromIndividualDeclaratonCodeAmountToPayload(individualDeclaration.baseComponents),
      complement: {
        changementPss: Boolean(individualDeclaration.base.pssModified),
        pss: individualDeclaration.base.pssModified != null ? individualDeclaration.base.pssModified.toString() : '',
      },
    } as IndividualDeclarationPayload

    return payload
  }

  static transformIndividualDeclarationToPayload(
    individualDeclaration: IndividualDeclaration,
  ): IndividualDeclarationPayload {
    const payload = {
      dateDebut: individualDeclaration.period.startDate,
      dateFin: individualDeclaration.period.endDate,
      tempsTravailBases: [
        {
          code: '01',
          mesure: individualDeclaration.workingTime.paidWorkingTime,
          uniteMesure: individualDeclaration.workingTime.workingUnit,
        },
      ],
      absences: Helper.transformIndividualDeclarationAbsencesToPayload(individualDeclaration.absences),
      heuresSupplementairesOuExceptionnelles: Helper.transformIndividualDeclarationExtraHoursToPayload(
        individualDeclaration.extraHours,
      ),
      salaires: Helper.transfromIndividualDeclaratonSalaryToPayload(individualDeclaration.salary),
      primes: Helper.transfromIndividualDeclaratonCodeAmountToPayload(individualDeclaration.bonus),
      indemnites: Helper.transfromIndividualDeclaratonCodeAmountToPayload(individualDeclaration.compensations),
      autreRevenusBruts: Helper.transfromIndividualDeclaratonCodeAmountToPayload(individualDeclaration.otherIncomes),
      bases: Helper.transfromIndividualDeclaratonBasesToPayload(individualDeclaration.base.fields),
      composantBases: Helper.transfromIndividualDeclaratonCodeAmountToPayload(individualDeclaration.baseComponents),
      cotisations: Helper.transformIndividualDeclarationContributionsToPayload(individualDeclaration.contributions),
      exonerations: Helper.transformIndividualDeclarationExemptionToPayload(individualDeclaration.exemptions),
      versement: {
        dateVersement: individualDeclaration.payment.paymentDate
          ? dateToFRFormat(individualDeclaration.payment.paymentDate)
          : '',
        netFiscal: individualDeclaration.totals.netTaxIncome,
        montantNet: individualDeclaration.payment.netPayable,
        assiettePas: individualDeclaration.withHoldingTax.base,
        brut: 0,
        periode: individualDeclaration.period.monthYear,
        tauxPas: individualDeclaration.withHoldingTax.tauxPAS,
        montantPas: individualDeclaration.withHoldingTax.amount,
        diffTauxPasMoisPrecedent: individualDeclaration.withHoldingTax.isTauxPASDiffFromPreviousMonth,
        netFiscalPot: individualDeclaration.totals.netPayableBeforeTax,
        avecIJSSBrut: Boolean(individualDeclaration.totals.grossIJSSAmount),
        montantIJSSBrut: individualDeclaration.totals.grossIJSSAmount,
        abattementPas: individualDeclaration.totals.deduction,
        montantNetSocial: individualDeclaration.totals.netSocialAmount,
        detailRnfApprentiStagiaire: Helper.transformIndividualDeclarationApprenticeDetailsInPayload(
          individualDeclaration.totals.netTaxIncomeApprenticeDetails,
        ),
      },
      complement: {
        changementPss: Boolean(individualDeclaration.base.pssModified),
        pss: individualDeclaration.base.pssModified != null ? individualDeclaration.base.pssModified.toString() : '',
      },
    } as IndividualDeclarationPayload

    return payload
  }

  static transformIndividualDeclarationApprenticeDetailsInPayload(
    netTaxIncomeApprenticeDetails: NetTaxIncomeApprenticeDetails | null,
  ): NetTaxIncomeApprenticeDetailsPayload | null {
    if (netTaxIncomeApprenticeDetails !== null) {
      return {
        smicAnnuel: netTaxIncomeApprenticeDetails.annualMinimumWages,
        cumulPrecedent: netTaxIncomeApprenticeDetails.previousAccumulation,
        netImposableBulletin: netTaxIncomeApprenticeDetails.netTaxableBulletin,
        cumulCourant: netTaxIncomeApprenticeDetails.currentAccumulation,
        rnf: netTaxIncomeApprenticeDetails.netTaxIncome,
        rnfProvisoire: netTaxIncomeApprenticeDetails.temporaryNetTaxIncome,
      }
    }
    return null
  }

  static transformIndividualDeclarationAbsencesToPayload(absences: EditDatableValue[]): AbsencePayload[] {
    const payload: AbsencePayload[] = []

    absences.forEach((absence) => {
      payload.push({
        sType: absence.key,
        sDebut: absence.startDate?.value ? dateToFRFormat(new Date(absence.startDate?.value)) : '',
        sFin: absence.endDate?.value ? dateToFRFormat(new Date(absence.endDate?.value)) : '',
        nombreHeures: absence.hours?.value ?? null,
        montant: absence.amount?.value ?? 0,
      })
    })

    return payload
  }

  static transformIndividualDeclarationExtraHoursToPayload(extraHours: EditDatableValue[]): ExtraHoursPayload[] {
    const payload: ExtraHoursPayload[] = []

    extraHours.forEach((extraHour) => {
      payload.push({
        code: extraHour.key,
        heures: extraHour.hours?.value ?? 0,
        montant: extraHour.amount?.value ?? 0,
      })
    })

    return payload
  }

  static transfromIndividualDeclaratonCodeAmountToPayload(elements: EditDatableValue[]): CodeAmountPayload[] {
    const payload: CodeAmountPayload[] = []

    elements.forEach((element) => {
      payload.push({
        code: element.key,
        montant: element.amount?.value ?? 0,
      })
    })

    return payload
  }

  static transfromIndividualDeclaratonBasesToPayload(bases: CodeAmount[]): CodeAmountPayload[] {
    const payload: CodeAmountPayload[] = []

    bases.forEach((base) => {
      payload.push({
        code: base.code,
        montant: base.amount,
      })
    })

    return payload
  }

  static transfromIndividualDeclaratonSalaryToPayload(salary: {
    uncappedMonthlyGross: number
    grossSubjectToUnemploymentInsuranceContributions: number
    reconstitutedGrossSalary: number
    basicGrossSalary: number
  }): CodeAmountPayload[] {
    const payload: CodeAmountPayload[] = []

    payload.push({ code: INDIVIDUAL_DECLARATION_SALARY.UNCAPPED_MONTHLY_GROSS, montant: salary.uncappedMonthlyGross })
    payload.push({
      code: INDIVIDUAL_DECLARATION_SALARY.GROSS_SUBJECT_TO_UNEMPLOYMENT_INSURANCE_CONTRIBUTIONS,
      montant: salary.grossSubjectToUnemploymentInsuranceContributions,
    })
    payload.push({
      code: INDIVIDUAL_DECLARATION_SALARY.RECONSTITUTED_GROSS_SALARY,
      montant: salary.reconstitutedGrossSalary,
    })
    payload.push({ code: INDIVIDUAL_DECLARATION_SALARY.BASIC_GROSS_SALARY, montant: salary.basicGrossSalary })

    return payload
  }

  static transformIndividualDeclarationContributionsToPayload(
    contributions: EditDatableValue[],
  ): ContributionPayload[] {
    const payload: ContributionPayload[] = []

    contributions.forEach((contribution) => {
      payload.push({
        identifiant: contribution.id ?? '',
        code: contribution.key,
        montantAssiette: contribution.base?.value ?? 0,
        montantCotisation: contribution.amount?.value ?? 0,
        taux: contribution.rate?.value ?? 0,
      })
    })

    return payload
  }

  static transformIndividualDeclarationExemptionToPayload(exemptions: EditDatableValue[]): ExemptionPayload[] {
    const payload: ExemptionPayload[] = []

    exemptions.forEach((exemption) => {
      payload.push({
        code: exemption.key,
        montantAssiette: exemption.base?.value ?? 0,
        montantExoneration: exemption.amount?.value ?? 0,
      })
    })

    return payload
  }

  static transformCompanyDeclarationPayload(payload: CompanyDeclarationPayload, id: number): CompanyDeclaration {
    const companyDeclaration: CompanyDeclaration = {
      id,
      period: {
        monthYear: payload.dateDebut ? payload.dateDebut.slice(3) : '',
        startDate: payload.dateDebut ?? '',
        endDate: payload.dateFin ?? '',
      },
      readonly: payload.modeLecture ?? false,
      status: payload.status ?? STATUS.ON_GOING,
      dockets: Helper.transformCompanyDeclarationBordereaux(payload.bordereaux),
      errorMap: Helper.transformCompanyDeclarationErrors(payload.mapErrorsByDisplayBlock),
      hasIndividualDeclarationMoreRecent: payload.hasDsnIndividuellePlusRecente,
    }

    if (payload.assujettissementFiscaux && payload.assujettissementFiscaux.length > 0) {
      companyDeclaration.dockets.push({
        socialOrganization: SOCIAL_ORGANIZATIONS.OTHER_TAX_LIABILITY,
        label: 'Assujettissement fiscal',
        identify: '',
        total: 0,
        contributions: Helper.transformtCompanyDeclarationAssujettissementFiscaux(payload.assujettissementFiscaux),
        specificContributions: [],
      })
    }

    return companyDeclaration
  }

  static transformCompanyDeclarationBordereaux(payload: CompanyDeclarationBordereau[]): CompanyDeclarationDocket[] {
    const dockets: CompanyDeclarationDocket[] = []

    payload.forEach((element) => {
      dockets.push({
        socialOrganization: element.codeOps,
        label: element.labelIdentifiant,
        identify: element.identifiant,
        total: element.totalCotisation,
        contributions: Helper.transformCompanyDeclarationCotisations(element.cotisations),
        specificContributions: Helper.transformCompanyDeclarationCotisationsSpecifiques(element.cotisationsSpeciques),
      })
    })

    return dockets
  }

  static transformCompanyDeclarationCotisations(
    payload: CompanyDeclarationCotisation[],
  ): CompanyDeclarationContribution[] {
    const contributions: CompanyDeclarationContribution[] = []

    payload.forEach((contribution) => {
      contributions.push(this.transformCompanyDeclarationCotisation(contribution))
    })

    return contributions
  }

  static transformCompanyDeclarationCotisation(payload: CompanyDeclarationCotisation): CompanyDeclarationContribution {
    const contribution: CompanyDeclarationContribution = {
      id: payload.identifiant,
      key: payload.codeType,
      base: {
        value: payload.nature === 'E' ? -payload.montantAssiette : payload.montantAssiette,
        required: true,
        canBeZero: true,
      },
      amount: {
        value: payload.nature === 'E' ? -payload.montantCotisation : payload.montantCotisation,
        required: true,
        canBeZero: true,
      },
      rate: { value: payload.taux, required: true, canBeZero: true },
      label: payload.label,
      count: payload.qualifiant,
      nature: payload.nature,
      isAmountEditable: payload.cotisationSaisissable,
      isBaseEditable: payload.assietteSaisissable,
      isRateEditable: payload.isTauxModifiable,
      isEditableWithLink: payload.isEditableWithLink,
      isRefreshable: payload.isRefreshable,
      isDeletable: payload.isDeletable,
      code: `${payload.codeOps}_${payload.codeType}_${payload.qualifiant}_${payload.ordre}_${payload.codeInsee}`,
      order: payload.ordre,
      codeInsee: payload.codeInsee,
    }

    return contribution
  }

  static transformCompanyDeclarationCotisationsSpecifiques(
    payload: CompanyDeclarationCotisationSpecifique[],
  ): CompanyDeclarationContribution[] {
    const contributions: CompanyDeclarationContribution[] = []

    payload.forEach((contribution) => {
      contributions.push({
        id: contribution.identifiantAdhesion,
        key: contribution.codeCotisation,
        label: contribution.label,
        count: contribution.referenceContrat,
        amount: { value: contribution.montantCotisation, required: true, canBeZero: true },
        code: `${contribution.codeOps}_${contribution.codeCotisation}`,
      })
    })

    return contributions
  }

  static transformtCompanyDeclarationAssujettissementFiscaux(
    payload: CompanyDeclarationAssujettissement[],
  ): CompanyDeclarationContribution[] {
    const contributions: CompanyDeclarationContribution[] = []

    payload.forEach((contribution) => {
      contributions.push({
        key: contribution.codeTaxe,
        base: { value: contribution.montant, required: true, canBeZero: true },
        label: contribution.label,
        code: `${contribution.codeTaxe}`,
      })
    })

    return contributions
  }

  static determineCompanyDeclarationPanelId(socialOrganization: SOCIAL_ORGANIZATIONS) {
    switch (socialOrganization) {
      case SOCIAL_ORGANIZATIONS.URSSAF:
        return COMPANY_DECLARATION_PANEL.URSSAF
      case SOCIAL_ORGANIZATIONS.AGIRC_ARRCO:
        return COMPANY_DECLARATION_PANEL.AGIRC_ARRCO
      case SOCIAL_ORGANIZATIONS.CONGES_SPECTACLES_AUDIENS:
        return COMPANY_DECLARATION_PANEL.CONGES_SPECTACLES_AUDIENS
      case SOCIAL_ORGANIZATIONS.FRANCE_TRAVAIL:
        return COMPANY_DECLARATION_PANEL.FRANCE_TRAVAIL
      case SOCIAL_ORGANIZATIONS.DGFIP:
        return COMPANY_DECLARATION_PANEL.DGFIP
      case SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE:
        return COMPANY_DECLARATION_PANEL.ORGANISME_COMPLEMENTAIRE
      case SOCIAL_ORGANIZATIONS.CIBTP:
        return COMPANY_DECLARATION_PANEL.CIBTP
      case SOCIAL_ORGANIZATIONS.OTHER_TAX_LIABILITY:
        return COMPANY_DECLARATION_PANEL.TAX_LIABILITY
    }
  }

  static determineCompanyDeclarationPanelHash(socialOrganization: SOCIAL_ORGANIZATIONS, identify: string) {
    switch (socialOrganization) {
      case SOCIAL_ORGANIZATIONS.URSSAF:
        return COMPANY_DECLARATION_PANEL.URSSAF
      case SOCIAL_ORGANIZATIONS.AGIRC_ARRCO:
        return COMPANY_DECLARATION_PANEL.AGIRC_ARRCO
      case SOCIAL_ORGANIZATIONS.CONGES_SPECTACLES_AUDIENS:
        return COMPANY_DECLARATION_PANEL.CONGES_SPECTACLES_AUDIENS
      case SOCIAL_ORGANIZATIONS.FRANCE_TRAVAIL:
        return COMPANY_DECLARATION_PANEL.FRANCE_TRAVAIL
      case SOCIAL_ORGANIZATIONS.DGFIP:
        return COMPANY_DECLARATION_PANEL.DGFIP
      case SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE:
        return `${COMPANY_DECLARATION_PANEL.ORGANISME_COMPLEMENTAIRE}_${identify}`
      case SOCIAL_ORGANIZATIONS.CIBTP:
        return COMPANY_DECLARATION_PANEL.CIBTP
      case SOCIAL_ORGANIZATIONS.OTHER_TAX_LIABILITY:
        return COMPANY_DECLARATION_PANEL.TAX_LIABILITY
    }
  }

  static determineRegularizationPanel(label: string) {
    switch (label) {
      case 'Temps de travail':
        return 'temps-de-travail'
      case 'Absences':
        return 'absences'
      case 'Primes':
        return 'primes'
      case 'Heures supp. et exceptionnelles':
        return 'heures-supplementaires'
    }
  }

  static determineCompanyDeclarationPanelLabel(socialOrganization: SOCIAL_ORGANIZATIONS) {
    switch (socialOrganization) {
      case SOCIAL_ORGANIZATIONS.URSSAF:
        return COMPANY_DECLARATION_PANEL_LABEL.URSSAF
      case SOCIAL_ORGANIZATIONS.AGIRC_ARRCO:
        return COMPANY_DECLARATION_PANEL_LABEL.AGIRC_ARRCO
      case SOCIAL_ORGANIZATIONS.CONGES_SPECTACLES_AUDIENS:
        return COMPANY_DECLARATION_PANEL_LABEL.CONGES_SPECTACLES_AUDIENS
      case SOCIAL_ORGANIZATIONS.FRANCE_TRAVAIL:
        return COMPANY_DECLARATION_PANEL_LABEL.FRANCE_TRAVAIL
      case SOCIAL_ORGANIZATIONS.DGFIP:
        return COMPANY_DECLARATION_PANEL_LABEL.DGFIP
      case SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE:
        return COMPANY_DECLARATION_PANEL_LABEL.ORGANISME_COMPLEMENTAIRE
      case SOCIAL_ORGANIZATIONS.CIBTP:
        return COMPANY_DECLARATION_PANEL_LABEL.CIBTP
      case SOCIAL_ORGANIZATIONS.OTHER_TAX_LIABILITY:
        return COMPANY_DECLARATION_PANEL_LABEL.TAX_LIABILITY
      default:
        return ''
    }
  }

  static determineCompanyDeclarationPanelLabelByEnum(panel: COMPANY_DECLARATION_PANEL) {
    switch (panel) {
      case COMPANY_DECLARATION_PANEL.URSSAF:
        return COMPANY_DECLARATION_PANEL_LABEL.URSSAF
      case COMPANY_DECLARATION_PANEL.AGIRC_ARRCO:
        return COMPANY_DECLARATION_PANEL_LABEL.AGIRC_ARRCO
      case COMPANY_DECLARATION_PANEL.CONGES_SPECTACLES_AUDIENS:
        return COMPANY_DECLARATION_PANEL_LABEL.CONGES_SPECTACLES_AUDIENS
      case COMPANY_DECLARATION_PANEL.FRANCE_TRAVAIL:
        return COMPANY_DECLARATION_PANEL_LABEL.FRANCE_TRAVAIL
      case COMPANY_DECLARATION_PANEL.DGFIP:
        return COMPANY_DECLARATION_PANEL_LABEL.DGFIP
      case COMPANY_DECLARATION_PANEL.ORGANISME_COMPLEMENTAIRE:
        return COMPANY_DECLARATION_PANEL_LABEL.ORGANISME_COMPLEMENTAIRE
      case COMPANY_DECLARATION_PANEL.CIBTP:
        return COMPANY_DECLARATION_PANEL_LABEL.CIBTP
      case COMPANY_DECLARATION_PANEL.TAX_LIABILITY:
        return COMPANY_DECLARATION_PANEL_LABEL.TAX_LIABILITY
      default:
        return ''
    }
  }

  static transformCompanyDeclarationErrors(
    payload?: Record<COMPANY_DECLARATION_PANEL, MappingErrorPayload>,
  ): Record<COMPANY_DECLARATION_PANEL, MappingError> {
    if (!payload) {
      return {
        'agirc-arrco': { messages: [], fields: [], messagesPerField: new Map<string, string[]>() },
        'assujettissement-fiscal': { messages: [], fields: [], messagesPerField: new Map<string, string[]>() },
        cibtp: { messages: [], fields: [], messagesPerField: new Map<string, string[]>() },
        'conges-spectacles': { messages: [], fields: [], messagesPerField: new Map<string, string[]>() },
        dgfip: { messages: [], fields: [], messagesPerField: new Map<string, string[]>() },
        'france-travail': { messages: [], fields: [], messagesPerField: new Map<string, string[]>() },
        'organisme-complementaire': { messages: [], fields: [], messagesPerField: new Map<string, string[]>() },
        urssaf: { messages: [], fields: [], messagesPerField: new Map<string, string[]>() },
      }
    }

    return {
      'agirc-arrco': {
        fields: payload[COMPANY_DECLARATION_PANEL.AGIRC_ARRCO]?.fields ?? [],
        messages: payload[COMPANY_DECLARATION_PANEL.AGIRC_ARRCO]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[COMPANY_DECLARATION_PANEL.AGIRC_ARRCO]?.messagesPerField),
      },
      'assujettissement-fiscal': {
        fields: payload[COMPANY_DECLARATION_PANEL.TAX_LIABILITY]?.fields ?? [],
        messages: payload[COMPANY_DECLARATION_PANEL.TAX_LIABILITY]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[COMPANY_DECLARATION_PANEL.TAX_LIABILITY]?.messagesPerField),
      },
      cibtp: {
        fields: payload[COMPANY_DECLARATION_PANEL.CIBTP]?.fields ?? [],
        messages: payload[COMPANY_DECLARATION_PANEL.CIBTP]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[COMPANY_DECLARATION_PANEL.CIBTP]?.messagesPerField),
      },
      'conges-spectacles': {
        fields: payload[COMPANY_DECLARATION_PANEL.CONGES_SPECTACLES_AUDIENS]?.fields ?? [],
        messages: payload[COMPANY_DECLARATION_PANEL.CONGES_SPECTACLES_AUDIENS]?.messages ?? [],
        messagesPerField: convertRecordToMap(
          payload[COMPANY_DECLARATION_PANEL.CONGES_SPECTACLES_AUDIENS]?.messagesPerField,
        ),
      },
      dgfip: {
        fields: payload[COMPANY_DECLARATION_PANEL.DGFIP]?.fields ?? [],
        messages: payload[COMPANY_DECLARATION_PANEL.DGFIP]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[COMPANY_DECLARATION_PANEL.DGFIP]?.messagesPerField),
      },
      'france-travail': {
        fields: payload[COMPANY_DECLARATION_PANEL.FRANCE_TRAVAIL]?.fields ?? [],
        messages: payload[COMPANY_DECLARATION_PANEL.FRANCE_TRAVAIL]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[COMPANY_DECLARATION_PANEL.FRANCE_TRAVAIL]?.messagesPerField),
      },
      'organisme-complementaire': {
        fields: payload[COMPANY_DECLARATION_PANEL.ORGANISME_COMPLEMENTAIRE]?.fields ?? [],
        messages: payload[COMPANY_DECLARATION_PANEL.ORGANISME_COMPLEMENTAIRE]?.messages ?? [],
        messagesPerField: convertRecordToMap(
          payload[COMPANY_DECLARATION_PANEL.ORGANISME_COMPLEMENTAIRE]?.messagesPerField,
        ),
      },
      urssaf: {
        fields: payload[COMPANY_DECLARATION_PANEL.URSSAF]?.fields ?? [],
        messages: payload[COMPANY_DECLARATION_PANEL.URSSAF]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[COMPANY_DECLARATION_PANEL.URSSAF]?.messagesPerField),
      },
    }
  }

  static transformIndividualDeclarationErrors(payload: Record<INDIVIDUAL_DECLARATION_PANEL, MappingErrorPayload>) {
    const errorMap: Record<INDIVIDUAL_DECLARATION_PANEL, MappingError> = {
      'temps-de-travail': {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME]?.messagesPerField),
      },
      absences: {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.ABSENCES]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.ABSENCES]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.ABSENCES]?.messagesPerField),
      },
      'heures-supplementaires': {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.EXTRA_HOURS]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.EXTRA_HOURS]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.EXTRA_HOURS]?.messagesPerField),
      },
      salaire: {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.SALARY]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.SALARY]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.SALARY]?.messagesPerField),
      },
      primes: {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.BONUS]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.BONUS]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.BONUS]?.messagesPerField),
      },
      indemnites: {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.COMPENSATIONS]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.COMPENSATIONS]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.COMPENSATIONS]?.messagesPerField),
      },
      'autres-revenus': {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.OTHER_INCOME]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.OTHER_INCOME]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.OTHER_INCOME]?.messagesPerField),
      },
      bases: {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.BASES]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.BASES]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.BASES]?.messagesPerField),
      },
      cotisations: {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.CONTRIBUTIONS]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.CONTRIBUTIONS]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.CONTRIBUTIONS]?.messagesPerField),
      },
      exonerations: {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.EXEMPTIONS]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.EXEMPTIONS]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.EXEMPTIONS]?.messagesPerField),
      },
      'totaux-avant-imposition': {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION]?.messages ?? [],
        messagesPerField: convertRecordToMap(
          payload[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION]?.messagesPerField,
        ),
      },
      'prelevement-source': {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.WITH_HOLDING_TAX]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.WITH_HOLDING_TAX]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.WITH_HOLDING_TAX]?.messagesPerField),
      },
      versement: {
        fields: payload[INDIVIDUAL_DECLARATION_PANEL.PAYMENT]?.fields ?? [],
        messages: payload[INDIVIDUAL_DECLARATION_PANEL.PAYMENT]?.messages ?? [],
        messagesPerField: convertRecordToMap(payload[INDIVIDUAL_DECLARATION_PANEL.PAYMENT]?.messagesPerField),
      },
    }

    errorMap[INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME].fields = errorMap[
      INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME
    ].fields.map((field) => {
      if (field === 'amesure_01') return 'paidWorkingTime'
      return field
    })

    if (
      payload[INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME] &&
      payload[INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME].messagesPerField
    ) {
      if (payload[INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME].messagesPerField['amesure_01']) {
        errorMap[INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME].messagesPerField?.set(
          'paidWorkingTime',
          payload[INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME].messagesPerField['amesure_01'],
        )
      }
    }

    errorMap[INDIVIDUAL_DECLARATION_PANEL.SALARY].fields = errorMap[INDIVIDUAL_DECLARATION_PANEL.SALARY].fields.map(
      (field) => {
        if (field === 'brutNonPlafonne') return 'uncappedMonthlyGross'
        if (field === 'brutContributionAC') return 'grossSubjectToUnemploymentInsuranceContributions'
        if (field === 'salaireRetabli') return 'reconstitutedGrossSalary'
        if (field === 'salaireBase') return 'basicGrossSalary'
        return field
      },
    )

    if (payload[INDIVIDUAL_DECLARATION_PANEL.SALARY] && payload[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField) {
      if (payload[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField['brutNonPlafonne']) {
        errorMap[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField?.set(
          'uncappedMonthlyGross',
          payload[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField['brutNonPlafonne'],
        )
      }
      if (payload[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField['brutContributionAC']) {
        errorMap[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField?.set(
          'grossSubjectToUnemploymentInsuranceContributions',
          payload[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField['brutContributionAC'],
        )
      }
      if (payload[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField['salaireRetabli']) {
        errorMap[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField?.set(
          'reconstitutedGrossSalary',
          payload[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField['salaireRetabli'],
        )
      }
      if (payload[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField['salaireBase']) {
        errorMap[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField?.set(
          'basicGrossSalary',
          payload[INDIVIDUAL_DECLARATION_PANEL.SALARY].messagesPerField['salaireBase'],
        )
      }
    }

    errorMap[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION].fields = errorMap[
      INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION
    ].fields.map((field) => {
      if (field === 'montantNetSocial') return 'netSocialAmount'
      else if (field === 'netFiscal') return 'netTaxIncome'
      return field
    })

    if (
      payload[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION] &&
      payload[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION].messagesPerField
    ) {
      if (payload[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION].messagesPerField['montantNetSocial']) {
        errorMap[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION].messagesPerField?.set(
          'netSocialAmount',
          payload[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION].messagesPerField['montantNetSocial'],
        )
      }
      if (payload[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION].messagesPerField['netFiscal']) {
        errorMap[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION].messagesPerField?.set(
          'netTaxIncome',
          payload[INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION].messagesPerField['netFiscal'],
        )
      }
    }

    errorMap[INDIVIDUAL_DECLARATION_PANEL.PAYMENT].fields = errorMap[INDIVIDUAL_DECLARATION_PANEL.PAYMENT].fields.map(
      (field) => {
        if (field === 'dateVersement') return 'paymentDate'
        else if (field === 'montantNet') return 'netPayableAfterTax'
        return field
      },
    )

    if (
      payload[INDIVIDUAL_DECLARATION_PANEL.PAYMENT] &&
      payload[INDIVIDUAL_DECLARATION_PANEL.PAYMENT].messagesPerField
    ) {
      if (payload[INDIVIDUAL_DECLARATION_PANEL.PAYMENT].messagesPerField['dateVersement']) {
        errorMap[INDIVIDUAL_DECLARATION_PANEL.PAYMENT].messagesPerField?.set(
          'paymentDate',
          payload[INDIVIDUAL_DECLARATION_PANEL.PAYMENT].messagesPerField['dateVersement'],
        )
      }
      if (payload[INDIVIDUAL_DECLARATION_PANEL.PAYMENT].messagesPerField['montantNet']) {
        errorMap[INDIVIDUAL_DECLARATION_PANEL.PAYMENT].messagesPerField?.set(
          'netPayable',
          payload[INDIVIDUAL_DECLARATION_PANEL.PAYMENT].messagesPerField['montantNet'],
        )
      }
    }

    return errorMap
  }

  static determineDocketTitle(docket: CompanyDeclarationDocket) {
    if (docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF) {
      return 'Bordereau ' + docket.label
    } else if (docket.socialOrganization === SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE) {
      return (
        'Bordereau ' +
        Helper.determineCompanyDeclarationPanelLabel(docket.socialOrganization) +
        ' (' +
        docket.label +
        ')'
      )
    } else if (!docket.socialOrganization) {
      return docket.label
    }
    return 'Bordereau ' + Helper.determineCompanyDeclarationPanelLabel(docket.socialOrganization)
  }

  static determineDocketNavTitle(docket: CompanyDeclarationDocket) {
    if (docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF || !docket.socialOrganization) {
      return docket.label
    } else if (docket.socialOrganization === SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE) {
      return 'OC (' + docket.label + ')'
    }
    return Helper.determineCompanyDeclarationPanelLabel(docket.socialOrganization)
  }

  static determineDocketOptionNavTitle(organization: EligibleSocialOrganization) {
    if (organization.code === SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE) {
      return `Organisme complémentaire (${organization.label})`
    } else if (organization.code === SOCIAL_ORGANIZATIONS.AGIRC_ARRCO) {
      return `${COMPANY_DECLARATION_PANEL_LABEL.AGIRC_ARRCO} (${organization.label})`
    }

    return organization.label
  }

  static determineIndividualDeclarationPanelLabelByEnum(panel: INDIVIDUAL_DECLARATION_PANEL) {
    switch (panel) {
      case INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.WORKING_TIME
      case INDIVIDUAL_DECLARATION_PANEL.ABSENCES:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.ABSENCES
      case INDIVIDUAL_DECLARATION_PANEL.EXTRA_HOURS:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.EXTRA_HOURS
      case INDIVIDUAL_DECLARATION_PANEL.SALARY:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.SALARY
      case INDIVIDUAL_DECLARATION_PANEL.BONUS:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.BONUS
      case INDIVIDUAL_DECLARATION_PANEL.COMPENSATIONS:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.COMPENSATIONS
      case INDIVIDUAL_DECLARATION_PANEL.OTHER_INCOME:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.OTHER_INCOME
      case INDIVIDUAL_DECLARATION_PANEL.BASES:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.BASES
      case INDIVIDUAL_DECLARATION_PANEL.CONTRIBUTIONS:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.CONTRIBUTIONS
      case INDIVIDUAL_DECLARATION_PANEL.EXEMPTIONS:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.EXEMPTIONS
      case INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.TOTALS_BEFORE_TAXATION
      case INDIVIDUAL_DECLARATION_PANEL.WITH_HOLDING_TAX:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.WITH_HOLDING_TAX
      case INDIVIDUAL_DECLARATION_PANEL.PAYMENT:
        return INDIVIDUAL_DECLARATION_PANEL_LABEL.PAYMENT
      default:
        return ''
    }
  }

  static determineIndividualDeclarationOption(id: string): ObjKeyValueString {
    switch (id) {
      case INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME:
        return {
          key: INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME,
          value: INDIVIDUAL_DECLARATION_PANEL_LABEL.WORKING_TIME,
        }
      case INDIVIDUAL_DECLARATION_PANEL.ABSENCES:
        return { key: INDIVIDUAL_DECLARATION_PANEL.ABSENCES, value: INDIVIDUAL_DECLARATION_PANEL_LABEL.ABSENCES }
      case INDIVIDUAL_DECLARATION_PANEL.EXTRA_HOURS:
        return { key: INDIVIDUAL_DECLARATION_PANEL.EXTRA_HOURS, value: INDIVIDUAL_DECLARATION_PANEL_LABEL.EXTRA_HOURS }
      case INDIVIDUAL_DECLARATION_PANEL.SALARY:
        return { key: INDIVIDUAL_DECLARATION_PANEL.SALARY, value: INDIVIDUAL_DECLARATION_PANEL_LABEL.SALARY }
      case INDIVIDUAL_DECLARATION_PANEL.BONUS:
        return { key: INDIVIDUAL_DECLARATION_PANEL.BONUS, value: INDIVIDUAL_DECLARATION_PANEL_LABEL.BONUS }
      case INDIVIDUAL_DECLARATION_PANEL.COMPENSATIONS:
        return {
          key: INDIVIDUAL_DECLARATION_PANEL.COMPENSATIONS,
          value: INDIVIDUAL_DECLARATION_PANEL_LABEL.COMPENSATIONS,
        }
      case INDIVIDUAL_DECLARATION_PANEL.OTHER_INCOME:
        return {
          key: INDIVIDUAL_DECLARATION_PANEL.OTHER_INCOME,
          value: INDIVIDUAL_DECLARATION_PANEL_LABEL.OTHER_INCOME,
        }
      case INDIVIDUAL_DECLARATION_PANEL.BASES:
        return { key: INDIVIDUAL_DECLARATION_PANEL.BASES, value: INDIVIDUAL_DECLARATION_PANEL_LABEL.BASES }
      case INDIVIDUAL_DECLARATION_PANEL.CONTRIBUTIONS:
        return {
          key: INDIVIDUAL_DECLARATION_PANEL.CONTRIBUTIONS,
          value: INDIVIDUAL_DECLARATION_PANEL_LABEL.CONTRIBUTIONS,
        }
      case INDIVIDUAL_DECLARATION_PANEL.EXEMPTIONS:
        return { key: INDIVIDUAL_DECLARATION_PANEL.EXEMPTIONS, value: INDIVIDUAL_DECLARATION_PANEL_LABEL.EXEMPTIONS }
      case INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION:
        return {
          key: INDIVIDUAL_DECLARATION_PANEL.TOTALS_BEFORE_TAXATION,
          value: INDIVIDUAL_DECLARATION_PANEL_LABEL.TOTALS_BEFORE_TAXATION,
        }
      case INDIVIDUAL_DECLARATION_PANEL.WITH_HOLDING_TAX:
        return {
          key: INDIVIDUAL_DECLARATION_PANEL.WITH_HOLDING_TAX,
          value: INDIVIDUAL_DECLARATION_PANEL_LABEL.WITH_HOLDING_TAX,
        }
      case INDIVIDUAL_DECLARATION_PANEL.PAYMENT:
        return { key: INDIVIDUAL_DECLARATION_PANEL.PAYMENT, value: INDIVIDUAL_DECLARATION_PANEL_LABEL.PAYMENT }
      default:
        return { key: '', value: '' }
    }
  }

  static transformCompanyDeclarationToPayload(companyDeclaration: CompanyDeclaration): CompanyDeclarationPayload {
    const payload: CompanyDeclarationPayload = {
      bordereaux: Helper.transformCompanyDeclarationDockets(companyDeclaration.dockets),
      assujettissementFiscaux: Helper.transformtCompanyDeclarationTaxLiability(
        companyDeclaration.dockets.find(
          (docket) => docket.socialOrganization === SOCIAL_ORGANIZATIONS.OTHER_TAX_LIABILITY,
        )?.contributions,
      ),
      cotisationDoeths: [],
      cotisationSoldeTaxeApprentissages: [],
    }

    return payload
  }

  static transformCompanyDeclarationDockets(dockets: CompanyDeclarationDocket[]): CompanyDeclarationBordereau[] {
    const payload: CompanyDeclarationBordereau[] = []

    dockets.forEach((docket) => {
      if (docket.socialOrganization !== SOCIAL_ORGANIZATIONS.OTHER_TAX_LIABILITY) {
        payload.push({
          codeOps: docket.socialOrganization,
          labelIdentifiant: docket.label,
          identifiant: docket.identify,
          totalCotisation: Math.round(docket.total * 100) / 100,
          cotisations: Helper.transformCompanyDeclarationContributions(docket.contributions),
          cotisationsSpeciques: Helper.transformCompanyDeclarationSpecificContributions(docket.specificContributions),
        })
      }
    })

    return payload
  }

  static transformCompanyDeclarationContributions(
    contributions: CompanyDeclarationContribution[],
  ): CompanyDeclarationCotisation[] {
    const payload: CompanyDeclarationCotisation[] = []

    contributions.forEach((contribution) => {
      payload.push(this.transformCompanyDeclarationContribution(contribution))
    })

    return payload
  }

  static transformCompanyDeclarationContribution(
    contribution: CompanyDeclarationContribution,
  ): CompanyDeclarationCotisation {
    const payload: CompanyDeclarationCotisation = {
      identifiant: contribution.id,
      codeType: contribution.key,
      montantAssiette:
        contribution.nature === 'E'
          ? contribution.base?.value
            ? -contribution.base.value
            : 0
          : (contribution.base?.value ?? 0),
      montantCotisation:
        contribution.nature === 'E'
          ? contribution.amount?.value
            ? -contribution.amount.value
            : 0
          : (contribution.amount?.value ?? 0),
      taux: contribution.rate?.value ?? null,
      qualifiant: contribution.count,
      nature: contribution.nature,
      ordre: contribution.order,
      codeInsee: contribution.codeInsee,
    }

    return payload
  }

  static transformCompanyDeclarationSpecificContributions(
    contributions: CompanyDeclarationContribution[],
  ): CompanyDeclarationCotisationSpecifique[] {
    const specificContributions: CompanyDeclarationCotisationSpecifique[] = []

    contributions.forEach((contribution) => {
      specificContributions.push({
        identifiantAdhesion: contribution.id,
        codeCotisation: contribution.key,
        referenceContrat: contribution.count ?? '',
        montantCotisation: contribution.amount?.value ?? 0,
      })
    })

    return specificContributions
  }

  static transformtCompanyDeclarationTaxLiability(
    contributions?: CompanyDeclarationContribution[],
  ): CompanyDeclarationAssujettissement[] {
    const payload: CompanyDeclarationAssujettissement[] = []

    if (contributions) {
      contributions.forEach((contribution) => {
        payload.push({
          codeTaxe: contribution.key,
          montant: contribution.base?.value ?? 0,
        })
      })
    }

    return payload
  }

  static getErrorsForOneField = (
    code: string,
    errors?: Map<string, string[]>,
    field?: 'assiette' | 'taux' | 'montant' | 'heures',
  ) => {
    const errorsByField = [] as string[]

    if (errors && errors instanceof Map) {
      if (errors.has('assiette_' + code) && (!field || field === 'assiette')) {
        errorsByField.push(...errors.get('assiette_' + code)!)
      } else if (errors.has('taux_' + code) && (!field || field === 'taux')) {
        errorsByField.push(...errors.get('taux_' + code)!)
      } else if (errors.has('montant_' + code) && (!field || field === 'montant')) {
        errorsByField.push(...errors.get('montant_' + code)!)
      }
    }

    return errorsByField
  }

  static transformStringArrayToHtmlList = (messages: string[]): string => {
    if (!messages || (messages && messages.length === 0)) return ''
    else if (messages && messages.length === 1) return messages[0]

    let htmlList = '<ul>'
    messages.every((message) => (htmlList += `<li>${message}</li>`))

    return (htmlList += '</ul>')
  }

  static transformContratSalarieEligibleToEligibleEmployeeContract(
    payload: ContratSalarieEligible[],
  ): EligibleEmployeeContract[] {
    const employeeContracts: EligibleEmployeeContract[] = payload.map((contrat) => {
      return {
        organisms: contrat.listeOrganismes,
        employeeId: contrat.salarieId,
        contractId: contrat.contratId,
        contractStart: contrat.debutContrat,
        contractEnd: contrat.finContrat,
        contractNumber: contrat.numeroContrat,
        contractType: contrat.typeContrat,
        contractLabel: contrat.labelContrat,
        lastname: contrat.nom,
        firstname: contrat.prenoms,
      }
    })
    return employeeContracts
  }

  static transformOrganismeEligibleToEligibleSocialOrganization(
    payload: OrganismeEligible[],
  ): EligibleSocialOrganization[] {
    const socialOrganizations: EligibleSocialOrganization[] = payload.map((socialOrganization) => {
      return {
        code: socialOrganization.codeOps,
        identify: socialOrganization.identifiant,
        label: socialOrganization.label,
      }
    })
    return socialOrganizations
  }

  static constructRegularizationEmployeeContributionPayload(
    contractId: string,
    periode: string,
    details: RegularizationEmployeeContribution,
  ): RegularizationEmployeeContributionPayload {
    const cotisationsPayload = details.cotisationsExonerations
      .filter((cotisation) => cotisation.code)
      .map((cotisation) => {
        return {
          code: cotisation.code,
          montant: cotisation.montant,
        }
      })
    const payload: RegularizationEmployeeContributionPayload = {
      contratId: contractId,
      periodeARegulariser: periode,
      cotisationExonerations: cotisationsPayload,
    }
    return payload
  }

  static constructRegularizationEmployeeBasePayload(
    contractId: string,
    periode: string,
    bases: RegularizationEmployeeBase[],
  ): RegularizationEmployeeBasePayload {
    const basesPayload = bases.map((base) => {
      return {
        code: base.code,
        montant: base.montant,
      }
    })
    const payload: RegularizationEmployeeBasePayload = {
      contratId: contractId,
      periodeARegulariser: periode,
      bases: basesPayload,
    }
    return payload
  }

  static transformRegularizationContractComplementaryOrganismToObjKeyValueString(
    options: RegularizationContractComplementaryOrganism[],
  ): ObjKeyValueString[] {
    return options.map((option) => {
      return { key: option.code, value: option.label }
    })
  }

  static transformRegularizationEmployeeFranceTravailToObjKeyValueString(
    options: RegularizationEmployeeFranceTravail[],
  ): ObjKeyValueString[] {
    return options.map((option) => {
      return { key: option.code, value: option.libelle }
    })
  }

  static transformRegularizationPayloadToRegularization(
    payload: RegularizationPayload,
    organizations: EligibleSocialOrganization[],
    employeeContracts: EligibleEmployeeContract[],
  ): Regularization {
    const regularisation: Regularization = {
      period: payload.periodeARegulariser,
      dockets: [],
      panels: [],
      readonly: payload.readOnly ?? false,
    }

    if (payload.regularisationUrssaf.dsnEntrepriseCotisations.length > 0) {
      const organization = organizations.find((organizations) => organizations.code === SOCIAL_ORGANIZATIONS.URSSAF)
      if (organization) {
        regularisation.dockets.push(
          Helper.transformRegularizationUrssafPayloadToDocket(
            payload.regularisationUrssaf.dsnEntrepriseCotisations,
            organization,
          ),
        )
      }
    }

    if (
      payload.regularisationAgircArrco.contratSalaries &&
      payload.regularisationAgircArrco.contratSalaries.length > 0
    ) {
      const organization = organizations.find(
        (organizations) => organizations.code === SOCIAL_ORGANIZATIONS.AGIRC_ARRCO,
      )
      if (organization) {
        regularisation.dockets.push(
          Helper.transformRegularizationAgircArrcoContractPayloadToDocket(
            payload.regularisationAgircArrco.contratSalaries,
            organization,
            employeeContracts,
          ),
        )
      }
    }

    if (
      payload.regularisationCongesSpectacles.contratSalaries &&
      payload.regularisationCongesSpectacles.contratSalaries.length > 0
    ) {
      const organization = organizations.find(
        (organizations) => organizations.code === SOCIAL_ORGANIZATIONS.CONGES_SPECTACLES_AUDIENS,
      )
      if (organization) {
        regularisation.dockets.push(
          Helper.transformRegularizationCongesSpectaclePayloadToDocket(
            payload.regularisationCongesSpectacles.contratSalaries,
            organization,
            employeeContracts,
          ),
        )
      }
    }

    if (payload.regularisationDgfip.contratSalaries && payload.regularisationDgfip.contratSalaries.length > 0) {
      const organization = organizations.find((organizations) => organizations.code === SOCIAL_ORGANIZATIONS.DGFIP)
      if (organization) {
        regularisation.dockets.push(
          Helper.transformRegularizationDGFIPPayloadToDocket(
            payload.regularisationDgfip.contratSalaries,
            organization,
            employeeContracts,
          ),
        )
      }
    }

    payload.regularisationOrganismeComplementaire.forEach((complementaryOrganism) => {
      if (
        complementaryOrganism.regularisationOcDetailSalarieContrats.length > 0 ||
        complementaryOrganism.regularisationOcDetailEtablissements.length > 0
      ) {
        const organization = {
          code: SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE,
          identify: complementaryOrganism.codeOrganisme,
          label: complementaryOrganism.libelleCodeOrganisme,
        }
        regularisation.dockets.push(
          Helper.transformRegularizationComplementaryOrganismPayloadToDocket(
            complementaryOrganism,
            organization,
            employeeContracts,
            payload.periodeARegulariser,
          ),
        )
      }
    })

    if (
      payload.regularisationCiBtp?.agregationEntreprise.heuresInterimaires ||
      payload.regularisationCiBtp?.agregationEntreprise.montantCA
    ) {
      const organization = organizations.find((organizations) => organizations.code === SOCIAL_ORGANIZATIONS.CIBTP)
      if (organization) {
        regularisation.dockets.push(
          Helper.transformRegularizationCIBTPPayloadToDocket(
            organization,
            payload.regularisationCiBtp.agregationEntreprise,
          ),
        )
      }
    }

    if (
      payload.regularisationPoleEmploiSpectacles?.contratSalaries &&
      payload.regularisationPoleEmploiSpectacles?.contratSalaries.length > 0
    ) {
      const organization = organizations.find((organizations) => organizations.code === SOCIAL_ORGANIZATIONS.DGFIP)
      if (organization) {
        regularisation.dockets.push(
          Helper.transformRegularizationFranceTravailPayloadToDocket(
            payload.regularisationPoleEmploiSpectacles.contratSalaries,
            organization,
            employeeContracts,
            payload.periodeARegulariser,
          ),
        )
      }
    }

    if (payload.regularisationAutresElements.regulTempsTravailBases.length > 0) {
      regularisation.panels.push(
        Helper.transformRegularizationWorkingTimePayloadToPanel(
          payload.regularisationAutresElements.regulTempsTravailBases,
          employeeContracts,
          payload.periodeARegulariser,
        ),
      )
    }

    if (payload.regularisationAutresElements.regulAbsences.length > 0) {
      regularisation.panels.push(
        Helper.transformRegularizationAbsencePayloadToPanel(
          payload.regularisationAutresElements.regulAbsences,
          employeeContracts,
          payload.periodeARegulariser,
        ),
      )
    }

    if (payload.regularisationAutresElements.regulPrimes.length > 0) {
      regularisation.panels.push(
        Helper.transformRegularizationBonusPayloadToPanel(
          payload.regularisationAutresElements.regulPrimes,
          employeeContracts,
          payload.periodeARegulariser,
        ),
      )
    }

    if (payload.regularisationAutresElements.regulIndemnites.length > 0) {
      regularisation.panels.push(
        Helper.transformRegularizationCompensationsPayloadToPanel(
          payload.regularisationAutresElements.regulIndemnites,
          employeeContracts,
          payload.periodeARegulariser,
        ),
      )
    }

    if (payload.regularisationAutresElements.regulBases.contratIds.length > 0) {
      regularisation.panels.push(
        Helper.transformRegularizationBasePayloadToPanel(
          payload.regularisationAutresElements.regulBases.contratIds,
          employeeContracts,
        ),
      )
    }

    if (payload.regularisationAutresElements.regulVersements.length > 0) {
      regularisation.panels.push(
        Helper.transformRegularizationPaymentPayloadToPanel(
          payload.regularisationAutresElements.regulVersements,
          employeeContracts,
        ),
      )
    }

    return regularisation
  }

  static transformRegularizationUrssafPayloadToDocket(
    contributions: CompanyDeclarationCotisation[],
    organization: EligibleSocialOrganization,
  ): CompanyDeclarationDocket {
    const docket: CompanyDeclarationDocket = {
      socialOrganization: SOCIAL_ORGANIZATIONS.URSSAF,
      label: organization.label,
      identify: organization.identify,
      total: 0,
      contributions: [],
      specificContributions: [],
    }

    contributions.forEach((contribution) => {
      docket.contributions.push(Helper.transformCompanyDeclarationCotisation(contribution))
    })

    return docket
  }

  static transformRegularizationCIBTPPayloadToDocket(
    organization: EligibleSocialOrganization,
    payload: RegularizationCIBTPPayload,
  ): CompanyDeclarationDocket {
    const docket: CompanyDeclarationDocket = {
      socialOrganization: SOCIAL_ORGANIZATIONS.CIBTP,
      label: organization.label,
      identify: organization.identify,
      total: 0,
      contributions: [],
      specificContributions: [],
    }

    docket.contributions.push(...Helper.determineRegularizationCIBTPContributions(payload))

    return docket
  }

  static determineRegularizationCIBTPContributions(
    payload?: RegularizationCIBTPPayload,
  ): CompanyDeclarationContribution[] {
    const contributions: CompanyDeclarationContribution[] = []

    contributions.push(
      {
        key: CIBTP_CONTRIBUTION_CODE.TURNOVER,
        label: CIBTP_CONTRIBUTION_LABEL.TURNOVER,
        amount: { value: payload?.montantCA ?? 0, required: true, canBeZero: false },
        code: CIBTP_CONTRIBUTION_CODE.TURNOVER,
      },
      {
        key: CIBTP_CONTRIBUTION_CODE.HOURS,
        label: CIBTP_CONTRIBUTION_LABEL.HOURS,
        amount: { value: payload?.heuresInterimaires ?? 0, required: true, canBeZero: false },
        code: CIBTP_CONTRIBUTION_CODE.HOURS,
      },
    )

    return contributions
  }

  // eslint-disable-next-line max-params
  static transformRegularizationFranceTravailPayloadToDocket(
    payload: RegularizationFranceTravailPayload[],
    organization: EligibleSocialOrganization,
    employeeContracts: EligibleEmployeeContract[],
    period: string,
  ): CompanyDeclarationDocket {
    const docket: CompanyDeclarationDocket = {
      socialOrganization: SOCIAL_ORGANIZATIONS.FRANCE_TRAVAIL,
      label: organization.label,
      identify: organization.identify,
      total: 0,
      contributions: [],
      specificContributions: [],
    }

    payload.forEach((contractPayload) => {
      const employeeContract = employeeContracts.find(
        (employeeContract) => employeeContract.contractId === contractPayload.contratId,
      )

      if (employeeContract) {
        docket.contributions.push({
          key: `Contrat n°${employeeContract.contractNumber} - ${employeeContract.contractLabel}`,
          label: `${employeeContract.lastname} ${capitalize(employeeContract.firstname)}`,
          base: { value: contractPayload.montantAssiette, required: true, canBeZero: false },
          rate: { value: contractPayload.taux, required: true, canBeZero: false },
          amount: { value: contractPayload.montantCotisation, required: true, canBeZero: false },
          item: {
            value: contractPayload.codeCotisation,
            required: true,
            options: [{ key: contractPayload.codeCotisation, value: contractPayload.libelleCotisation ?? '' }],
            loading: false,
            needCall: true,
            info: {
              period,
              id: `${employeeContract.contractId}`,
              panel: COMPANY_DECLARATION_PANEL.FRANCE_TRAVAIL,
            },
          },
          code: `${employeeContract.contractId}`,
        })
      }
    })

    return docket
  }

  static transformRegularizationAgircArrcoContractPayloadToDocket(
    payload: RegularizationAgircArrcoContractPayload[],
    organization: EligibleSocialOrganization,
    employeeContracts: EligibleEmployeeContract[],
  ): CompanyDeclarationDocket {
    const docket: CompanyDeclarationDocket = {
      socialOrganization: SOCIAL_ORGANIZATIONS.AGIRC_ARRCO,
      label: organization.label,
      identify: organization.identify,
      total: 0,
      contributions: [],
      specificContributions: [],
    }

    payload.forEach((contractPayload) => {
      const employeeContract = employeeContracts.find(
        (employeeContract) => employeeContract.contractId === contractPayload.contratId,
      )

      if (employeeContract) {
        docket.contributions.push({
          key: `Contrat n°${employeeContract.contractNumber} - ${employeeContract.contractLabel}`,
          label: `${employeeContract.lastname} ${capitalize(employeeContract.firstname)}`,
          amount: { value: contractPayload.montantRegularisation, required: true, canBeZero: true },
          base: { value: contractPayload.plafonne, required: true, canBeZero: true },
          otherBase: { value: contractPayload.deplafonne, required: true, canBeZero: true },
          code: employeeContract.contractId.toString(),
        })
      }
    })

    return docket
  }

  static transformRegularizationCongesSpectaclePayloadToDocket(
    payload: RegularizationCongesSpectaclePayload[],
    organization: EligibleSocialOrganization,
    employeeContracts: EligibleEmployeeContract[],
  ): CompanyDeclarationDocket {
    const docket: CompanyDeclarationDocket = {
      socialOrganization: SOCIAL_ORGANIZATIONS.CONGES_SPECTACLES_AUDIENS,
      label: organization.label,
      identify: organization.identify,
      total: 0,
      contributions: [],
      specificContributions: [],
    }

    payload.forEach((contractPayload) => {
      const employeeContract = employeeContracts.find(
        (employeeContract) => employeeContract.contractId === contractPayload.contratId,
      )

      if (employeeContract) {
        docket.contributions.push({
          key: `Contrat n°${employeeContract.contractNumber} - ${employeeContract.contractLabel}`,
          label: `${employeeContract.lastname} ${capitalize(employeeContract.firstname)}`,
          amount: { value: contractPayload.montant, required: true, canBeZero: true },
          code: employeeContract.contractId.toString(),
        })
      }
    })

    return docket
  }

  static transformRegularizationDGFIPPayloadToDocket(
    payload: RegularizationDGFIPPayload[],
    organization: EligibleSocialOrganization,
    employeeContracts: EligibleEmployeeContract[],
  ): CompanyDeclarationDocket {
    const docket: CompanyDeclarationDocket = {
      socialOrganization: SOCIAL_ORGANIZATIONS.DGFIP,
      label: organization.label,
      identify: organization.identify,
      total: 0,
      contributions: [],
      specificContributions: [],
    }

    payload.forEach((contractPayload) => {
      const employeeContract = employeeContracts.find(
        (employeeContract) => employeeContract.contractId === contractPayload.contratId,
      )

      if (employeeContract) {
        docket.contributions.push({
          key: `Contrat n°${employeeContract.contractNumber} - ${employeeContract.contractLabel}`,
          label: `${employeeContract.lastname} ${capitalize(employeeContract.firstname)}`,
          amount: { value: contractPayload.montantRegulPas, required: true, canBeZero: true },
          code: employeeContract.contractId.toString(),
        })
      }
    })

    return docket
  }

  // eslint-disable-next-line max-params
  static transformRegularizationComplementaryOrganismPayloadToDocket(
    payload: RegularizationComplementaryOrganismPayload,
    organization: EligibleSocialOrganization,
    employeeContracts: EligibleEmployeeContract[],
    period: string,
  ): CompanyDeclarationDocket {
    const docket: CompanyDeclarationDocket = {
      socialOrganization: SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE,
      label: organization.label,
      identify: organization.identify,
      total: 0,
      contributions: [],
      specificContributions: [],
    }

    payload.regularisationOcDetailSalarieContrats.forEach((contractPayload) => {
      const employeeContract = employeeContracts.find(
        (employeeContract) => employeeContract.contractId === contractPayload.contratId,
      )

      if (employeeContract) {
        docket.contributions.push({
          key: `Contrat n°${employeeContract.contractNumber} - ${employeeContract.contractLabel}`,
          label: `${employeeContract.lastname} ${capitalize(employeeContract.firstname)}`,
          amount: { value: contractPayload.montantCotisation, required: true, canBeZero: true },
          code: `${organization.identify}_${employeeContract.contractId}`,
          item: {
            value: contractPayload.referenceContratOc,
            required: true,
            options: [{ key: contractPayload.referenceContratOc, value: contractPayload.libelleContratOc ?? '' }],
            loading: false,
            needCall: true,
            info: {
              period,
              id: `${organization.identify}_${employeeContract.contractId}`,
              panel: COMPANY_DECLARATION_PANEL.ORGANISME_COMPLEMENTAIRE,
            },
          },
        })
      }
    })

    payload.regularisationOcDetailEtablissements.forEach((contractPayload) => {
      docket.specificContributions.push({
        key: contractPayload.referenceContratOc,
        label: contractPayload.libelleContrat,
        amount: { value: contractPayload.montantCotisation, required: true, canBeZero: false },
        code: `${contractPayload.codeOrganisme}_${contractPayload.referenceContratOc}`,
      })
    })

    return docket
  }

  static transformRegularizationWorkingTimePayloadToPanel(
    payload: RegularizationWorkingTimePayload[],
    employeeContracts: EligibleEmployeeContract[],
    period: string,
  ): RegularizationPanel {
    const panel: RegularizationPanel = {
      id: INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME,
      contributions: [],
    }

    payload.forEach((contractPayload) => {
      const employeeContract = employeeContracts.find(
        (employeeContract) => employeeContract.contractId === contractPayload.contratId,
      )

      if (employeeContract) {
        panel.contributions.push({
          key: `Contrat n°${employeeContract.contractNumber} - ${employeeContract.contractLabel}`,
          label: `${employeeContract.lastname} ${capitalize(employeeContract.firstname)}`,
          amount: { value: contractPayload.mesure, required: true, canBeZero: true },
          code: `${employeeContract.contractId}`,
          item: {
            value: contractPayload.uniteMesure,
            required: true,
            options: [{ key: contractPayload.uniteMesure, value: contractPayload.libelleMesure ?? '' }],
            loading: false,
            needCall: true,
            info: {
              period,
              id: `${employeeContract.contractId}`,
              panel: INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME,
            },
          },
        })
      }
    })

    return panel
  }

  static transformRegularizationAbsencePayloadToPanel(
    payload: RegularizationAbsencePayload[],
    employeeContracts: EligibleEmployeeContract[],
    period: string,
  ): RegularizationPanel {
    const panel: RegularizationPanel = {
      id: INDIVIDUAL_DECLARATION_PANEL.ABSENCES,
      contributions: [],
    }

    payload.forEach((contractPayload) => {
      const employeeContract = employeeContracts.find(
        (employeeContract) => employeeContract.contractId === contractPayload.contratId,
      )

      if (employeeContract) {
        panel.contributions.push({
          key: `Contrat n°${employeeContract.contractNumber} - ${employeeContract.contractLabel}`,
          label: `${employeeContract.lastname} ${capitalize(employeeContract.firstname)}`,
          amount: {
            value: contractPayload.montant,
            required: contractPayload.montant != null,
            canBeZero: true,
          },
          hours: {
            value: contractPayload.heures,
            required: contractPayload.heures != null,
            canBeZero: false,
          },
          startDate: {
            value: new Date(dateFRtoBasic(contractPayload.debut)),
            required: Boolean(contractPayload.debut),
          },
          endDate: {
            value: new Date(dateFRtoBasic(contractPayload.fin)),
            required: Boolean(contractPayload.fin),
          },
          code: `${employeeContract.contractId}`,
          item: {
            value: contractPayload.type,
            required: true,
            options: [{ key: contractPayload.type, value: contractPayload.libelle ?? '' }],
            loading: false,
            needCall: true,
            info: {
              period,
              id: `${employeeContract.contractId}`,
              panel: INDIVIDUAL_DECLARATION_PANEL.ABSENCES,
            },
          },
        })
      }
    })

    return panel
  }

  static transformRegularizationBonusPayloadToPanel(
    payload: RegularizationBonusPayload[],
    employeeContracts: EligibleEmployeeContract[],
    period: string,
  ): RegularizationPanel {
    const panel: RegularizationPanel = {
      id: INDIVIDUAL_DECLARATION_PANEL.BONUS,
      contributions: [],
    }

    payload.forEach((contractPayload) => {
      const employeeContract = employeeContracts.find(
        (employeeContract) => employeeContract.contractId === contractPayload.contratId,
      )

      if (employeeContract) {
        panel.contributions.push({
          key: `Contrat n°${employeeContract.contractNumber} - ${employeeContract.contractLabel}`,
          label: `${employeeContract.lastname} ${capitalize(employeeContract.firstname)}`,
          amount: { value: contractPayload.montant, required: true, canBeZero: true },
          code: `${employeeContract.contractId}`,
          item: {
            value: contractPayload.code,
            required: true,
            options: [{ key: contractPayload.code, value: contractPayload.libelle ?? '' }],
            loading: false,
            needCall: true,
            info: {
              period,
              id: `${employeeContract.contractId}`,
              panel: INDIVIDUAL_DECLARATION_PANEL.BONUS,
            },
          },
        })
      }
    })

    return panel
  }

  static transformRegularizationCompensationsPayloadToPanel(
    payload: RegularizationBonusPayload[],
    employeeContracts: EligibleEmployeeContract[],
    period: string,
  ): RegularizationPanel {
    const panel: RegularizationPanel = {
      id: INDIVIDUAL_DECLARATION_PANEL.COMPENSATIONS,
      contributions: [],
    }

    payload.forEach((contractPayload) => {
      const employeeContract = employeeContracts.find(
        (employeeContract) => employeeContract.contractId === contractPayload.contratId,
      )

      if (employeeContract) {
        panel.contributions.push({
          key: `Contrat n°${employeeContract.contractNumber} - ${employeeContract.contractLabel}`,
          label: `${employeeContract.lastname} ${capitalize(employeeContract.firstname)}`,
          amount: { value: contractPayload.montant, required: true, canBeZero: true },
          code: `${employeeContract.contractId}`,
          item: {
            value: contractPayload.code,
            required: true,
            options: [{ key: contractPayload.code, value: contractPayload.libelle ?? '' }],
            loading: false,
            needCall: true,
            info: {
              period,
              id: `${employeeContract.contractId}`,
              panel: INDIVIDUAL_DECLARATION_PANEL.COMPENSATIONS,
            },
          },
        })
      }
    })

    return panel
  }

  static transformRegularizationPaymentPayloadToPanel(
    payload: RegularizationPaymentPayload[],
    employeeContracts: EligibleEmployeeContract[],
  ): RegularizationPanel {
    const panel: RegularizationPanel = {
      id: INDIVIDUAL_DECLARATION_PANEL.PAYMENT,
      contributions: [],
    }

    payload.forEach((contractPayload) => {
      const employeeContract = employeeContracts.find(
        (employeeContract) => employeeContract.contractId === contractPayload.contratId,
      )

      if (employeeContract) {
        panel.contributions.push({
          key: `Contrat n°${employeeContract.contractNumber} - ${employeeContract.contractLabel}`,
          label: `${employeeContract.lastname} ${capitalize(employeeContract.firstname)}`,
          amount: { value: contractPayload.montantNetSocial, required: true, canBeZero: true },
          code: `${employeeContract.contractId}`,
        })
      }
    })

    return panel
  }

  static transformRegularizationBasePayloadToPanel(
    payload: number[],
    employeeContracts: EligibleEmployeeContract[],
  ): RegularizationPanel {
    const panel: RegularizationPanel = {
      id: INDIVIDUAL_DECLARATION_PANEL.BASES,
      contributions: [],
    }

    payload.forEach((contractId) => {
      const employeeContract = employeeContracts.find((employeeContract) => employeeContract.contractId === contractId)

      if (employeeContract) {
        panel.contributions.push({
          key: `Contrat n°${employeeContract.contractNumber} - ${employeeContract.contractLabel}`,
          label: `${employeeContract.lastname} ${capitalize(employeeContract.firstname)}`,
          code: `${employeeContract.contractId}`,
        })
      }
    })

    return panel
  }

  static constructRegularizationEmployeeDgfipPayload(
    regularisation: RegularizationEmployeeDgfip,
    contractId: string,
    period: string,
  ): RegularizationEmployeeDgfipPayload {
    const payload: RegularizationEmployeeDgfipPayload = {
      periodeARegulariser: period,
      contratId: contractId,
      assiettePas: regularisation.assiettePas,
      tauxPas: regularisation.tauxPas,
      montantPas: regularisation.montantPas,
      dgfipRegularisationType: regularisation.dgfipRegularisationType,
      abattementPas: regularisation.abattementPas,
      partNonImposable: regularisation.partNonImposablePas,
      brutDeclarePas: regularisation.brutDeclarePas,
    }

    return payload
  }

  static transformRegularizationToRegularizationPayload(regularization: Regularization): RegularizationPayload {
    const payload: RegularizationPayload = {
      periodeARegulariser: regularization.period!,
      regularisationAgircArrco: { contratSalarieIds: [] },
      regularisationCongesSpectacles: { contratSalaries: [] },
      regularisationDgfip: { contratSalarieIds: [] },
      regularisationOrganismeComplementaire: [],
      regularisationUrssaf: { dsnEntrepriseCotisations: [] },
      regularisationCiBtp: { agregationEntreprise: { montantCA: 0, heuresInterimaires: 0 } },
      regularisationPoleEmploiSpectacles: { contratSalaries: [] },
      regularisationAutresElements: {
        regulTempsTravailBases: [],
        regulAbsences: [],
        regulPrimes: [],
        regulIndemnites: [],
        regulBases: { contratIds: [] },
        regulVersements: [],
      },
    }

    regularization.dockets.forEach((docket) => {
      if (docket.socialOrganization === SOCIAL_ORGANIZATIONS.URSSAF) {
        payload.regularisationUrssaf.dsnEntrepriseCotisations = Helper.transformCompanyDeclarationContributions(
          docket.contributions,
        )
      } else if (docket.socialOrganization === SOCIAL_ORGANIZATIONS.AGIRC_ARRCO) {
        payload.regularisationAgircArrco.contratSalarieIds = docket.contributions.map(
          (contribution) => contribution.code,
        )
      } else if (docket.socialOrganization === SOCIAL_ORGANIZATIONS.DGFIP) {
        payload.regularisationDgfip.contratSalarieIds = docket.contributions.map((contribution) => contribution.code)
      } else if (docket.socialOrganization === SOCIAL_ORGANIZATIONS.ORGANISME_COMPLEMENTAIRE) {
        payload.regularisationOrganismeComplementaire.push(
          Helper.transformCompanyDeclarationDocketToRegularizationComplementaryOrganismPayload(docket),
        )
      } else if (docket.socialOrganization === SOCIAL_ORGANIZATIONS.CONGES_SPECTACLES_AUDIENS) {
        payload.regularisationCongesSpectacles.contratSalaries =
          Helper.transformCompanyDeclarationDocketToRegularizationCongesSpectaclePayload(docket)
      } else if (docket.socialOrganization === SOCIAL_ORGANIZATIONS.CIBTP) {
        payload.regularisationCiBtp!.agregationEntreprise =
          Helper.transformCompanyDeclarationDocketToRegularizationCIBTPPayload(docket)
      } else if (docket.socialOrganization === SOCIAL_ORGANIZATIONS.FRANCE_TRAVAIL) {
        payload.regularisationPoleEmploiSpectacles!.contratSalaries =
          Helper.transformCompanyDeclarationDocketToRegularizationPoleEmploiPayload(docket)
      }
    })

    regularization.panels.forEach((panel) => {
      if (panel.id === INDIVIDUAL_DECLARATION_PANEL.BASES) {
        payload.regularisationAutresElements.regulBases.contratIds = panel.contributions.map((contribution) =>
          Number(contribution.code),
        )
      } else if (panel.id === INDIVIDUAL_DECLARATION_PANEL.WORKING_TIME) {
        payload.regularisationAutresElements.regulTempsTravailBases =
          Helper.transformRegularizationPanelToRegularizationWorkingTimePayload(panel)
      } else if (panel.id === INDIVIDUAL_DECLARATION_PANEL.ABSENCES) {
        payload.regularisationAutresElements.regulAbsences =
          Helper.transformRegularizationPanelToRegularizationAbsencePayload(panel)
      } else if (panel.id === INDIVIDUAL_DECLARATION_PANEL.BONUS) {
        payload.regularisationAutresElements.regulPrimes =
          Helper.transformRegularizationPanelToRegularizationBonusPayload(panel)
      } else if (panel.id === INDIVIDUAL_DECLARATION_PANEL.COMPENSATIONS) {
        payload.regularisationAutresElements.regulIndemnites =
          Helper.transformRegularizationPanelToRegularizationBonusPayload(panel)
      } else if (panel.id === INDIVIDUAL_DECLARATION_PANEL.PAYMENT) {
        payload.regularisationAutresElements.regulVersements =
          Helper.transformRegularizationPanelToRegularizationPaymentPayload(panel)
      }
    })

    return payload
  }

  static transformCompanyDeclarationDocketToRegularizationPoleEmploiPayload(
    docket: CompanyDeclarationDocket,
  ): RegularizationFranceTravailPayload[] {
    const payload: RegularizationFranceTravailPayload[] = []

    docket.contributions.forEach((contribution) => {
      payload.push({
        contratId: Number(contribution.code),
        codeCotisation: contribution.item?.value ?? '',
        montantAssiette: contribution.base?.value ?? 0,
        taux: contribution.rate?.value ?? 0,
        montantCotisation: contribution.amount?.value ?? 0,
      })
    })

    return payload
  }

  static transformCompanyDeclarationDocketToRegularizationComplementaryOrganismPayload(
    docket: CompanyDeclarationDocket,
  ): RegularizationComplementaryOrganismPayload {
    const payload: RegularizationComplementaryOrganismPayload = {
      codeOrganisme: docket.identify,
      libelleCodeOrganisme: docket.label,
      regularisationOcDetailEtablissements: [],
      regularisationOcDetailSalarieContrats: [],
    }

    docket.contributions.forEach((contribution) => {
      const codeSplit = contribution.code.split('_')
      payload.regularisationOcDetailSalarieContrats.push({
        contratId: codeSplit[1],
        referenceContratOc: contribution.item?.value ?? '',
        montantCotisation: contribution.amount?.value ?? 0,
      })
    })

    docket.specificContributions.forEach((contribution) => {
      const codeSplit = contribution.code.split('_')
      payload.regularisationOcDetailEtablissements.push({
        referenceContratOc: codeSplit[1],
        montantCotisation: contribution.amount?.value ?? 0,
      })
    })

    return payload
  }

  static transformCompanyDeclarationDocketToRegularizationCongesSpectaclePayload(
    docket: CompanyDeclarationDocket,
  ): RegularizationCongesSpectaclePayload[] {
    const payload: RegularizationCongesSpectaclePayload[] = []

    docket.contributions.forEach((contribution) => {
      payload.push({
        contratId: Number(contribution.code),
        montant: contribution.amount?.value ?? 0,
      })
    })

    return payload
  }

  static transformCompanyDeclarationDocketToRegularizationCIBTPPayload(
    docket: CompanyDeclarationDocket,
  ): RegularizationCIBTPPayload {
    const payload: RegularizationCIBTPPayload = {
      montantCA: 0,
      heuresInterimaires: 0,
    }

    docket.contributions.forEach((contribution) => {
      if (contribution.code === CIBTP_CONTRIBUTION_CODE.TURNOVER) {
        payload.montantCA = contribution.amount?.value ?? 0
      } else if (contribution.code === CIBTP_CONTRIBUTION_CODE.HOURS) {
        payload.heuresInterimaires = contribution.amount?.value ?? 0
      }
    })

    return payload
  }

  static transformRegularizationPanelToRegularizationWorkingTimePayload(
    panel: RegularizationPanel,
  ): RegularizationWorkingTimePayload[] {
    const payload: RegularizationWorkingTimePayload[] = []

    panel.contributions.forEach((contribution) => {
      payload.push({
        contratId: Number(contribution.code),
        code: '01',
        mesure: contribution.amount?.value ?? 0,
        uniteMesure: contribution.item?.value ?? '',
      })
    })

    return payload
  }

  static transformRegularizationPanelToRegularizationAbsencePayload(
    panel: RegularizationPanel,
  ): RegularizationAbsencePayload[] {
    const payload: RegularizationAbsencePayload[] = []

    panel.contributions.forEach((contribution) => {
      payload.push({
        contratId: Number(contribution.code),
        type: contribution.item?.value ?? '',
        debut: contribution.startDate?.value ? dateToFRFormat(new Date(contribution.startDate.value)) : '',
        fin: contribution.endDate?.value ? dateToFRFormat(new Date(contribution.endDate.value)) : '',
        montant: contribution.amount?.value ?? null,
        heures: contribution.hours?.value ?? 0,
      })
    })

    return payload
  }

  static transformRegularizationPanelToRegularizationBonusPayload(
    panel: RegularizationPanel,
  ): RegularizationBonusPayload[] {
    const payload: RegularizationBonusPayload[] = []

    panel.contributions.forEach((contribution) => {
      payload.push({
        contratId: Number(contribution.code),
        code: contribution.item?.value ?? '',
        montant: contribution.amount?.value ?? 0,
      })
    })

    return payload
  }

  static transformRegularizationPanelToRegularizationPaymentPayload(
    panel: RegularizationPanel,
  ): RegularizationPaymentPayload[] {
    const payload: RegularizationPaymentPayload[] = []

    panel.contributions.forEach((contribution) => {
      payload.push({
        contratId: Number(contribution.code),
        montantNetSocial: contribution.amount?.value ?? 0,
      })
    })

    return payload
  }
}
