import { defineStore } from 'pinia'
import { teledsnAPI } from '@/services/teledsn-api'
import { router } from '@/routes'
import { useMainStore } from './main'
import {
  AdditionalInformationsPayload,
  CategoriesPayload,
  ComplementaryOrgMembershipsPayload,
  Contract,
  ContractStatus,
  GROUPEMENT,
  GeneralInformationsContractPayload,
  Options,
  SPECIFIC_STATUS,
  StatusSpecificationsPayload,
  WorkingHoursAndWagesPayload,
} from '@/models/contract.model'
import { useEmployeeStore } from './employee'
import { Router } from '@/models/router.model'
import { dateFRtoBasic, dateToFRFormat } from '@/utils/index'
import { useCompanyStore } from './company'
import { Helper } from '@/models/contract.helper'
import { EmployeeContract } from '@/models/employee.model'

type State = {
  contract: Contract
  loading: boolean
  subLoading: boolean
  options: Options
  form: { isSubmitted: boolean; haveEmptyFields: boolean }
}

export const useContractStore = defineStore('contractStore', {
  state: (): State => ({
    contract: {
      id: null,
      globalInformations: {
        contractType: null,
        startDate: null,
        endDate: null,
        label: '',
        workPlace: '',
      },
      statusSpecifications: {
        specificStatus: null,
        publicAssistancePolicy: null,
        preparedTraining: null,
        hiringReason: null,
        casualEntertainmentWorkerType: null,
      },
      socioProfessionalCategory: {
        group: null,
        category: null,
        complement: null,
        section: null,
        jobClassification: null,
        ableToContributeToExecutiveRetirement: { loading: false, result: null },
      },
      workingHoursAndWages: {
        workingUnit: null,
        monthlyLegalCompanyHours: null,
        monthlyContractHour: null,
        workingTimeMode: null,
        monthlyGrossWage: null,
      },
      additionalInformations: {
        complementaryPension: null,
        professionalExpenseRate: 0,
        noUnemploymentInsuranceContribution: false,
        additionalContributionAgingRisk: false,
        workerAbroad: '99',
        arduousness: 'sans',
        riskFactor: [],
        disabledWorker: '00',
        workAccidentRate: null,
      },
      complementaryOrgMemberships: [],
      contractNumber: null,
      active: false,
    },
    options: {
      contractTypes: [],
      hiringReasonOptions: [],
      publicAssistancePolicies: [],
      casualEntertainmentWorkerStatus: [],
      diplomas: [],
      categories: [],
      groups: [],
      complements: [],
      sections: [],
      jobClassifications: [],
      workingUnitOptions: [],
      workingTimeModeOptions: [],
      complementaryPensions: [],
      professionalExpenseRates: [],
      disabledWorkers: [],
      arduousExposures: [],
      riskFactors: [],
      workerAbroads: [],
      workAccidentRates: [],
    },
    loading: false,
    subLoading: false,
    form: { isSubmitted: false, haveEmptyFields: false },
  }),
  getters: {
    isFirstStep() {
      return router.currentRoute.value.meta.isFirstStep
    },
    isLastStep() {
      return router.currentRoute.value.meta.isLastStep
    },
    isContractExpired() {
      return (dateFin: string) => new Date(dateFRtoBasic(dateFin)) < new Date()
    },
    determineContratStatus() {
      return (contract: EmployeeContract) => {
        if (!contract.actif) return ContractStatus.BEING_CREATED
        else if (contract.finContrat && this.isContractExpired(contract.finContrat)) return ContractStatus.FINISHED
        return ContractStatus.ACTIVE
      }
    },
    getContractTypeLabel() {
      return () =>
        this.options.contractTypes.find((option) => option.key === this.contract.globalInformations.contractType)
          ?.value ?? ''
    },
    getWorkingTimeModeLabel() {
      return () =>
        this.options.workingTimeModeOptions.find(
          (option) => option.key === this.contract.workingHoursAndWages.workingTimeMode,
        )?.value ?? ''
    },
    isContractStatusCadre() {
      return () =>
        this.contract.socioProfessionalCategory.group === GROUPEMENT.ENTREPRENEUR ||
        this.contract.socioProfessionalCategory.group === GROUPEMENT.EXECUTIVES_AND_HIGHER_INTELLECTUAL_PROFESSIONS
    },
    isApprentice() {
      return () => this.contract.statusSpecifications.specificStatus === SPECIFIC_STATUS.EMPLOYEE_APPRENTICE
    },
  },
  actions: {
    previousStep() {
      let previousStep = ''
      const companyStore = useCompanyStore()

      switch (router.currentRoute.value.name) {
        case Router.STEP_GENERAL_INFORMATIONS + '-contrat':
          previousStep = Router.EMPLOYEES
          break
        case Router.STEP_STATUS:
          previousStep = Router.CONTRACT + Router.STEP_GENERAL_INFORMATIONS
          break
        case Router.STEP_CATEGORY:
          previousStep = Router.CONTRACT + Router.STEP_STATUS
          break
        case Router.STEP_WORKING_HOURS_WAGES:
          previousStep = Router.CONTRACT + Router.STEP_CATEGORY
          break
        case Router.COMPLEMENTARY_ORG_MEMBERSHIP:
          previousStep = Router.CONTRACT + Router.STEP_WORKING_HOURS_WAGES
          break
        case Router.STEP_COMPLEMENTARY_INFORMATIONS:
          previousStep = companyStore.individualComplementaryOrganismsAvailable()
            ? Router.CONTRACT + Router.COMPLEMENTARY_ORG_MEMBERSHIP
            : Router.CONTRACT + Router.STEP_WORKING_HOURS_WAGES
          break
        default:
          previousStep = '/'
      }

      router.push(previousStep)
    },
    async nextStep() {
      const mainStore = useMainStore()
      const companyStore = useCompanyStore()

      mainStore.resetServerMessages()
      let nextStep = ''
      this.form.isSubmitted = true

      if (!this.form.haveEmptyFields) {
        switch (router.currentRoute.value.name) {
          case Router.STEP_GENERAL_INFORMATIONS + '-contrat':
            nextStep = Router.CONTRACT + Router.STEP_STATUS
            if (this.contract.id) await this.modifyContractGeneralInformations()
            else await this.createNewContract()
            break
          case Router.STEP_STATUS:
            nextStep = Router.CONTRACT + Router.STEP_CATEGORY
            await this.modifyContractStatusSpecifications()
            break
          case Router.STEP_CATEGORY:
            nextStep = Router.CONTRACT + Router.STEP_WORKING_HOURS_WAGES
            await this.modifyContractCategory()
            break
          case Router.STEP_WORKING_HOURS_WAGES:
            nextStep = companyStore.individualComplementaryOrganismsAvailable()
              ? Router.CONTRACT + Router.COMPLEMENTARY_ORG_MEMBERSHIP
              : Router.CONTRACT + Router.STEP_COMPLEMENTARY_INFORMATIONS
            await this.modifyContractWorkingHoursAndWages()
            break
          case Router.COMPLEMENTARY_ORG_MEMBERSHIP:
            nextStep = Router.CONTRACT + Router.STEP_COMPLEMENTARY_INFORMATIONS
            await this.modifyContractComplementaryOrgMemberships()
            break
          case Router.STEP_COMPLEMENTARY_INFORMATIONS:
            nextStep = Router.EMPLOYEES
            await this.modifyContractAdditionalInformations()
            break
          default:
            nextStep = '/'
        }
      } else {
        mainStore.addErrorIfNotAlreadyPresent({ value: 'Un ou plusieurs champs sont manquants' })
      }

      if (!mainStore.serverResponseStatut.errorMessages || mainStore.serverResponseStatut.errorMessages.length === 0) {
        router.push(nextStep)
      }
    },
    async getContractTypes() {
      try {
        const response = await teledsnAPI.getContractTypes()
        this.options.contractTypes = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des types de contrat.',
        })
      }
    },
    async getReasonOptionsForContract() {
      try {
        const response = await teledsnAPI.getReasonOptionsForContract()
        this.options.hiringReasonOptions = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des motifs de contrat.',
        })
      }
    },
    async getPublicAssistancePolicies() {
      try {
        const response = await teledsnAPI.getPublicAssistancePolicies()
        this.options.publicAssistancePolicies = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value:
            'Une erreur est survenue lors de la récupération des options des dispositifs politiques d’aide publique.',
        })
      }
    },
    async getDiplomasOptions() {
      try {
        const response = await teledsnAPI.getDiplomasOptions()
        this.options.diplomas = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des options des diplômes.',
        })
      }
    },
    async getCasualEntertainmentWorkerTypes() {
      try {
        const response = await teledsnAPI.getCasualEntertainmentWorkerTypes()
        this.options.casualEntertainmentWorkerStatus = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des options des statuts intermittent',
        })
      }
    },
    async getGroups() {
      try {
        const response = await teledsnAPI.getFamillesPcsEse(
          this.contract.statusSpecifications.casualEntertainmentWorkerType,
        )
        this.options.groups = response
        if (this.options.groups.length === 1) {
          this.contract.socioProfessionalCategory.group = this.options.groups[0].key
        }
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des groupements.',
        })
      }
    },
    async getCategories() {
      try {
        const response = await teledsnAPI.getCategoriesPcsEse(
          this.contract.socioProfessionalCategory.group!,
          this.contract.statusSpecifications.publicAssistancePolicy,
          this.contract.statusSpecifications.casualEntertainmentWorkerType,
        )
        this.options.categories = response
        if (this.options.categories.length === 1) {
          this.contract.socioProfessionalCategory.category = this.options.categories[0].key
        }
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des catégories.',
        })
      }
    },
    async getComplements() {
      try {
        const companyId = useCompanyStore().company.id
        const response = await teledsnAPI.getComplementsPcsEse(
          companyId!,
          this.contract.socioProfessionalCategory.category!,
          this.contract.statusSpecifications.casualEntertainmentWorkerType,
          this.contract.socioProfessionalCategory.section,
        )
        this.options.complements = response
        if (this.options.complements.length === 1) {
          this.contract.socioProfessionalCategory.complement = this.options.complements[0].key
        }
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des compléments de catégorie.',
        })
      }
    },
    async getSections() {
      try {
        const response = await teledsnAPI.getBranchesCiBTP()
        this.options.sections = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des branches.',
        })
      }
    },
    async getJobClassifications() {
      try {
        const companyId = useCompanyStore().company.id
        const response = await teledsnAPI.getClassificationMetiersCiBTP(
          companyId!,
          this.contract.socioProfessionalCategory.category,
          this.contract.socioProfessionalCategory.section,
          this.contract.socioProfessionalCategory.complement,
        )
        this.options.jobClassifications = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des classifications métiers.',
        })
      }
    },
    async getComplementaryPensionForCategory() {
      try {
        this.contract.socioProfessionalCategory.ableToContributeToExecutiveRetirement.loading = true
        const response = await teledsnAPI.getRetraiteComplementaireParPcsEse(
          this.contract.socioProfessionalCategory.category,
          this.contract.socioProfessionalCategory.complement,
          this.contract.statusSpecifications.casualEntertainmentWorkerType,
        )
        this.contract.socioProfessionalCategory.ableToContributeToExecutiveRetirement.result = response.retraite
          ? response.retraite === 'Catégorie cotisant à la Retraite cadre'
          : null
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération de la retraire complémentaire.',
        })
      } finally {
        this.contract.socioProfessionalCategory.ableToContributeToExecutiveRetirement.loading = false
      }
    },
    async createNewContract() {
      const mainStore = useMainStore()
      const employeeStore = useEmployeeStore()
      try {
        this.subLoading = true

        let dateDebutFormatFR = ''
        let dateFinFormatFR = null
        if (this.contract.globalInformations.startDate) {
          dateDebutFormatFR = dateToFRFormat(new Date(this.contract.globalInformations.startDate))
        }
        if (this.contract.globalInformations.endDate) {
          dateFinFormatFR = dateToFRFormat(new Date(this.contract.globalInformations.endDate))
        }

        const payload: GeneralInformationsContractPayload = {
          nature: this.contract.globalInformations.contractType!,
          dateDebut: dateDebutFormatFR,
          dateFin: dateFinFormatFR,
          libelleEmploi: this.contract.globalInformations.label,
          codeInseeLieuTravail: this.contract.globalInformations.workPlace,
        }

        const response = await teledsnAPI.createNewContract(employeeStore.employee.id!, payload)
        this.contract.id = response.id
        this.contract.contractNumber = response.numero
        this.contract.active = response.actif
        mainStore.serverResponseStatut.successMessage = `Votre contrat a bien été créé.`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async modifyContractGeneralInformations() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        let dateDebutFormatFR = ''
        let dateFinFormatFR = null
        if (this.contract.globalInformations.startDate) {
          dateDebutFormatFR = dateToFRFormat(new Date(this.contract.globalInformations.startDate))
        }
        if (this.contract.globalInformations.endDate) {
          dateFinFormatFR = dateToFRFormat(new Date(this.contract.globalInformations.endDate))
        }

        const payload: GeneralInformationsContractPayload = {
          nature: this.contract.globalInformations.contractType!,
          dateDebut: dateDebutFormatFR,
          dateFin: dateFinFormatFR,
          libelleEmploi: this.contract.globalInformations.label,
          codeInseeLieuTravail: this.contract.globalInformations.workPlace,
        }

        await teledsnAPI.modifyContractGeneralInformations(this.contract.id!, payload)

        mainStore.serverResponseStatut.successMessage = `Les informations générales de votre contrat ont bien été modifiés.`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async modifyContractStatusSpecifications() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        const payload: StatusSpecificationsPayload = {
          nature: this.contract.globalInformations.contractType!,
          dirigeantSalarie: this.contract.statusSpecifications.specificStatus === SPECIFIC_STATUS.EMPOYEE_MANAGER,
          agentPriveFonctionPublique:
            this.contract.statusSpecifications.specificStatus === SPECIFIC_STATUS.PRIVATE_CIVIL_SERVICE_AGENT,
          apprentiSalarie: this.contract.statusSpecifications.specificStatus === SPECIFIC_STATUS.EMPLOYEE_APPRENTICE,
          dispositifAidePublique: this.contract.statusSpecifications.publicAssistancePolicy,
          typeIntermitentSpectacle: this.contract.statusSpecifications.casualEntertainmentWorkerType,
          diplomeApprenti: this.contract.statusSpecifications.preparedTraining,
          motifRecours: this.contract.statusSpecifications.hiringReason,
          aideDomicilePersonneFragile:
            this.contract.statusSpecifications.specificStatus === SPECIFIC_STATUS.HOME_HELP_FOR_FRAGILE_PEOPLE,
        }

        await teledsnAPI.modifyContractStatusOptions(this.contract.id!, payload)
        mainStore.serverResponseStatut.successMessage = `Le statut de votre contrat a bien été modifié.`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async getWorkingUnitOptions() {
      try {
        const response = await teledsnAPI.getWorkingUnitOptions()
        this.options.workingUnitOptions = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des unités de travail.',
        })
      }
    },
    async getWorkingTimeModeOptions() {
      try {
        const response = await teledsnAPI.getWorkingTimeModeOptions()
        this.options.workingTimeModeOptions = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des modalités de temps de travail.',
        })
      }
    },
    async modifyContractCategory() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        const payload: CategoriesPayload = {
          nature: this.contract.globalInformations.contractType!,
          dirigeantSalarie: this.contract.statusSpecifications.specificStatus === 'Dirigeant Salarié',
          typeIntermitentSpectacle: this.contract.statusSpecifications.casualEntertainmentWorkerType,
          famillePcsEse: this.contract.socioProfessionalCategory.group!,
          branchePcsEse: this.contract.socioProfessionalCategory.section,
          pcsEse: this.contract.socioProfessionalCategory.category!,
          complementPcsEse: this.contract.socioProfessionalCategory.complement,
          classificationMetier: this.contract.socioProfessionalCategory.jobClassification,
        }

        await teledsnAPI.modifyContractCategory(this.contract.id!, payload)
        mainStore.serverResponseStatut.successMessage = `La catégorie de votre contrat a bien été modifié.`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async modifyContractWorkingHoursAndWages() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        const payload: WorkingHoursAndWagesPayload = {
          nature: this.contract.globalInformations.contractType!,
          numero: this.contract.contractNumber!,
          uniteMesure: this.contract.workingHoursAndWages.workingUnit!,
          quotiteEntreprise: this.contract.workingHoursAndWages.monthlyLegalCompanyHours!,
          quotiteContrat: this.contract.workingHoursAndWages.monthlyContractHour!,
          pcsEse: this.contract.socioProfessionalCategory.category!,
          modaliteTemps: this.contract.workingHoursAndWages.workingTimeMode!,
          brutMensuel: this.contract.workingHoursAndWages.monthlyGrossWage!,
        }

        await teledsnAPI.modifyContractWorkingHoursAndWages(this.contract.id!, payload)
        mainStore.serverResponseStatut.successMessage = `Le temps de travail et le salaire de votre contrat ont bien été modifiés.`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async getProfessionalExpenseRates() {
      try {
        const response = await teledsnAPI.getProfessionalExpenseRates()
        this.options.professionalExpenseRates = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des taux frais professionnels.',
        })
      }
    },
    async getDisabledWorkers() {
      try {
        const response = await teledsnAPI.getDisabledWorkers()
        this.options.disabledWorkers = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des travailleurs handicapés.',
        })
      }
    },
    async getArduousExposures() {
      try {
        const response = await teledsnAPI.getArduousExposures()
        this.options.arduousExposures = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des pénibilités.',
        })
      }
    },
    async getRiskFactors() {
      try {
        const response = await teledsnAPI.getRiskFactors()
        this.options.riskFactors = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération des facteurs de risques.',
        })
      }
    },
    async getWorkerAbroads() {
      try {
        const response = await teledsnAPI.getWorkerAbroads()
        this.options.workerAbroads = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération de la liste détaché/expatrié.',
        })
      }
    },
    async getComplementaryPensions() {
      const companyStore = useCompanyStore()
      try {
        if (
          !companyStore.options.complementaryPensionReferences ||
          !companyStore.options.complementaryPensionReferences.length
        ) {
          await companyStore.getComplementaryPensionReferences()
        }

        const companyComplementaryPensions = companyStore.getCompanyComplementaryPensions()

        if (companyComplementaryPensions) {
          this.options.complementaryPensions = companyStore.options.complementaryPensionReferences.filter(
            (complementary) => companyComplementaryPensions.includes(complementary.key),
          )

          if (this.options.complementaryPensions && this.options.complementaryPensions.length === 1) {
            this.contract.additionalInformations.complementaryPension = this.options.complementaryPensions[0].key
          }
        }
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération de la liste des retraite complémentaire',
        })
      }
    },
    async getContractWorkAccidentRates() {
      try {
        const response = await teledsnAPI.getContractWorkAccidentRates(this.contract.id!)
        this.options.workAccidentRates = response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération de la liste des taux accidents de travail.',
        })
      }
    },
    async modifyContractAdditionalInformations() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        const payload: AdditionalInformationsPayload = {
          retraiteComplementaire: this.contract.additionalInformations.complementaryPension!,
          tauxFraisProfessionnels: this.contract.additionalInformations.professionalExpenseRate
            ? this.contract.additionalInformations.professionalExpenseRate
            : 0,
          pasAssuChomage: this.contract.additionalInformations.noUnemploymentInsuranceContribution,
          tpsPartielCotizComplemRisqueVieillesse: this.contract.additionalInformations.additionalContributionAgingRisk,
          detacheExpatrie: this.contract.additionalInformations.workerAbroad!,
          penibilite: this.contract.additionalInformations.arduousness!,
          facteursRisquePenibilite: this.contract.additionalInformations.riskFactor,
          codeTravailleurHandicape: this.contract.additionalInformations.disabledWorker!,
          modaliteTemps: this.contract.workingHoursAndWages.workingTimeMode!,
          ordreTauxAt:
            this.contract.additionalInformations.workAccidentRate != null
              ? this.contract.additionalInformations.workAccidentRate
              : 0,
        }

        await teledsnAPI.modifyContractAdditionalInformations(this.contract.id!, payload)
        mainStore.serverResponseStatut.successMessage = `Les informations complémentaire de votre contrat ont bien été modifiés.`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async setSelectedComplementaryOrgs(complementaryOrgMemberships: ComplementaryOrgMembershipsPayload[]) {
      const companyStore = useCompanyStore()
      try {
        if (
          !companyStore.company.socialOrganizations.configurationSheet?.ficheIndividuelles ||
          !companyStore.company.socialOrganizations.configurationSheet?.ficheIndividuelles.length
        ) {
          await companyStore.getSocialOrganizationConfigurationSheet()
        }

        const individualFiles = companyStore.company.socialOrganizations.configurationSheet?.ficheIndividuelles

        if (individualFiles && individualFiles.length) {
          this.contract.complementaryOrgMemberships = individualFiles.filter((sheet) => {
            return complementaryOrgMemberships.find((org) =>
              org.codeOption
                ? org.codeOption === sheet.codeOption && org.referenceContrat === sheet.referenceContrat
                : org.referenceContrat === sheet.referenceContrat,
            )
          })
        }
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: "Une erreur est survenue lors de la préselection de l'affiliation.",
        })
      }
    },
    async getContract() {
      this.loading = true
      try {
        const response = await teledsnAPI.getContract(this.contract.id!)
        this.contract = Helper.storingDataFromAPI(response)
        await this.setSelectedComplementaryOrgs(response.affiliationsOrganismesComplementaires)
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération du contrat.',
        })
      } finally {
        this.loading = false
      }
    },
    async deleteContract(contractId: number) {
      const mainStore = useMainStore()
      try {
        this.loading = true
        await teledsnAPI.deleteContract(contractId)
        mainStore.serverResponseStatut.successMessage = 'Le contrat a bien été supprimé'
      } catch (error) {
        mainStore.setServerResponseFromError(error)
      } finally {
        this.loading = false
      }
    },
    setContractFromEmployeeList(employeeContrat: EmployeeContract) {
      this.contract.id = employeeContrat.contratId
      this.contract.contractNumber = employeeContrat.numeroContrat
      this.contract.deletable = employeeContrat.supprimable
    },
    async modifyContractComplementaryOrgMemberships() {
      const mainStore = useMainStore()
      try {
        this.subLoading = true

        const payload: ComplementaryOrgMembershipsPayload[] = this.contract.complementaryOrgMemberships.map((org) =>
          Helper.constructComplementaryOrgPayload(org),
        )
        await teledsnAPI.modifyContractComplementaryOrgMemberships(this.contract.id!, payload)
        mainStore.serverResponseStatut.successMessage = `Les affiliations aux organismes complémentaires de votre contrat ont bien été modifiés.`
      } catch (error) {
        mainStore.setServerResponseFromError(error, true)
      } finally {
        this.subLoading = false
      }
    },
    async getWorkPlateAndSetIt() {
      try {
        const response = await teledsnAPI.getLocalityFromInsee(this.contract.globalInformations.workPlace)
        return response
      } catch (error) {
        useMainStore().addErrorIfNotAlreadyPresent({
          value: 'Une erreur est survenue lors de la récupération du lieu de travail.',
        })
      }
    },
  },
  persist: {
    afterRestore: (ctx) => {
      if (ctx.pinia.state?.value?.contractStore?.contract?.globalInformations?.startDate) {
        ctx.pinia.state.value.contractStore.contract.globalInformations.startDate = new Date(
          ctx.pinia.state.value.contractStore.contract.globalInformations.startDate,
        )
      }
      if (ctx.pinia.state?.value?.contractStore?.contract?.globalInformations?.endDate) {
        ctx.pinia.state.value.contractStore.contract.globalInformations.endDate = new Date(
          ctx.pinia.state.value.contractStore.contract.globalInformations.endDate,
        )
      }
    },
  },
})
