<script setup lang="ts">
import { useVModel } from '@vueuse/core'
import { Companies } from '@/models/main.model'
import CompaniesDropdown from './CompaniesDropdown.vue'
import { useMainStore } from '@/stores/main'
import { Router } from '@/models/router.model'
import { router } from '@/routes'

type Props = {
  visible: boolean
  selectedCompany: Companies | null
}

const props = defineProps<Props>()
const emit = defineEmits(['update:selectedCompany'])

const mainStore = useMainStore()
const selectedCompany = useVModel(props, 'selectedCompany', emit)

const goToCompanyForm = () => {
  router.push(Router.COMPANY + Router.STEP_GENERAL_INFORMATIONS)
  mainStore.forceSelectionCompany = false
}
</script>

<template>
  <Dialog
    :visible="props.visible"
    modal
    blockScroll
    :draggable="false"
    :closable="false"
    :header="mainStore.haveCompanies ? 'Sélectionner une entreprise' : 'Bienvenue'"
    class="darker-mask"
  >
    <div v-if="mainStore.haveCompanies">
      <Message severity="info" :closable="false">
        La page à laquelle vous souhaitez accéder nécessite d'avoir une entreprise sélectionnée !
      </Message>
      <CompaniesDropdown v-model:selectedCompany="selectedCompany" openDirectly class="w-full" />
    </div>
    <Message v-else severity="info" :closable="false">
      Avant d'effectuer votre première déclaration mensuelle, nous vous invitons à ajouter une entreprise !
    </Message>
    <template v-if="!mainStore.haveCompanies" #footer>
      <Button label="Définir une entreprise" @click="goToCompanyForm()" />
    </template>
  </Dialog>
</template>
