<script setup lang="ts">
import { useEmployeeStore } from '@/stores/employee'
import { computed, ref, watch, onMounted } from 'vue'
import { Employee } from '@/models/employee.model'

type Props = {
  placeholder: string
  label?: string
  onlyActive?: boolean
  disabled?: boolean
}

const props = defineProps<Props>()
const employeeStore = useEmployeeStore()

const employees = computed(() => {
  if (props.onlyActive) {
    return employeeStore.employees.filter((employee) => employee.actif)
  }
  return employeeStore.employees
})
const selectedEmployee = ref(null as Employee | null)

onMounted(() => {
  selectedEmployee.value = employees.value.find((employee) => employee.salarieId === employeeStore.employee.id) ?? null
})

watch(selectedEmployee, async () => {
  if (selectedEmployee.value?.salarieId) {
    employeeStore.employee.id = selectedEmployee.value.salarieId
    await employeeStore.getEmployeeContracts(employeeStore.employee.id)
  } else {
    employeeStore.employee.id = null
    employeeStore.employee.contracts = []
  }
})

watch(
  () => employeeStore.employee.id,
  (employeeId) => {
    if (employeeId) {
      selectedEmployee.value =
        employees.value.find((employee) => employee.salarieId === employeeStore.employee.id) ?? null
    }
  },
)
</script>

<template>
  <span class="p-float-label">
    <Dropdown
      v-if="employees && employees.length > 0"
      showClear
      id="employee"
      :filterFields="['nom', 'prenoms']"
      filterPlaceholder="Rechercher par nom ou prénom"
      v-model="selectedEmployee"
      :options="employees"
      :placeholder="props.placeholder"
      scrollHeight="250px"
      dataKey="salarieId"
      filter
      :disabled="props.disabled"
      class="w-full"
    >
      <template #value="slotProps">
        <span v-if="slotProps.value">
          <span>{{ slotProps.value.nom }}</span>
          <span v-if="slotProps.value.prenoms" class="capitalize">
            &nbsp;{{ slotProps.value.prenoms.toLocaleLowerCase() }}
          </span>
          <Tag
            :value="slotProps.value.actif ? 'En activité' : 'Inactif'"
            :severity="slotProps.value.actif ? 'success' : 'warning'"
            class="ml-2"
          />
        </span>
        <span v-else>{{ slotProps.placeholder }}</span>
      </template>
      <template #option="slotProps">
        <span>{{ slotProps.option.nom }}</span>
        <span v-if="slotProps.option.prenoms" class="capitalize">
          &nbsp;{{ slotProps.option.prenoms.toLocaleLowerCase() }}
        </span>
        <Tag
          :value="slotProps.option.actif ? 'En activité' : 'Inactif'"
          :severity="slotProps.option.actif ? 'success' : 'warning'"
          class="ml-2"
        />
      </template>
    </Dropdown>
    <label v-if="props.label" for="employee">{{ props.label }}</label>
  </span>
</template>
